import React, { useState } from 'react';
import './FormElements.css';
import Grid from "@mui/material/Grid";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import { ParseHelperCopy } from '../Helpers/Helpers';


export default function BoolSelect(props) {

    // logic for handling helper copy before or after main control    
    let helpercopy_pre = "";
    let helpercopy_post = "";
    if (props.helper) {
        const helperobj = ParseHelperCopy(props.helper);
        if (helperobj.pre.length > 0) {
            helpercopy_pre = <span dangerouslySetInnerHTML={{ __html: helperobj.pre }} ></span>;
        }
        if (helperobj.post.length > 0) {
            helpercopy_post = <span dangerouslySetInnerHTML={{ __html: helperobj.post }} ></span>;
        }        
    } 
    // end of helper copy handling

    // handle required label
    let labelreq = "";
    if (props.req) {
        labelreq = " *";
    }

    let invert_values = false;
    if (props.custom_props) {
        if (props.custom_props.invert_values) {
            invert_values = props.custom_props.invert_values;
        }
    } 

    // handle values passed as boolean or as text
    // for pre-selection
    let booloptions = [];    
    const [defvalue] = useState(props.value);
    if (props.options.length === 2) { 

        // option 1, default value is true, unless custom prop invertvalues is specified        
        let option1 = <FormControlLabel key="option1" value="true" control={<Radio />} label={props.options[0]} />;
        if (invert_values) {           
            option1 = <FormControlLabel key="option1" value="false" control={<Radio />} label={props.options[0]} />;
        }

        // option 2, default value is false, unless custom prop invertvalues is specified   
        let option2 = <FormControlLabel key="option2" value="false" control={<Radio />} label={props.options[1]} />;
        if (invert_values) {            
            option2 = <FormControlLabel key="option2" value="true" control={<Radio />} label={props.options[1]} />
        }

        booloptions.push(option1);
        booloptions.push(option2);        
    }
   
    return (
        <Grid container spacing={1}>
            <Grid item xs={4} sx={{ marginTop: 1 }}>
                <label htmlFor={props.name} >{props.label} </label>
                <Box component="span" sx={{ color: 'red' }}>
                    {labelreq}
                </Box>
            </Grid>
            <Grid item xs={8}>
                <Box
                    sx={{
                        width: 800,
                        maxWidth: '100%',
                        alignItems: 'left'
                    }}
                >
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <FormLabel id={props.name + '-label'}></FormLabel>
                        {helpercopy_pre}
                        <RadioGroup
                            row
                            defaultValue={defvalue}
                            aria-labelledby={props.name + '-label'}
                            name={props.name}
                            onChange={props.onChange}
                        >
                        {booloptions}
                        </RadioGroup> 
                        <FormHelperText sx={{ marginLeft: 0 }}>{helpercopy_post}</FormHelperText>
                    </FormControl>
                </Box>
            </Grid>
        </Grid>
    );
}
import React, { useState } from 'react';
import useSession from '../../useSession';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import SaveIcon from '@mui/icons-material/Save';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { SaveFilm } from '../ApiClient/ApiClient';
import { ParseFormSectionConfig, GetRolesArray, GetFormFilmObject, UpdateRolePrimaries } from '../Helpers/Helpers'
import FormContentList from "../FormElements/FormContentList";
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MainNav from '../MainNav/MainNav';

export default function Section() {

    let navigate = useNavigate();

    const { sessionItem, setSession } = useSession();
    const [sbopen, setSbOpen] = useState(false);
    const [opMessage, setOpMessage] = useState("");    

    let myfilm = sessionItem.film;
    const filmid = sessionItem.film.filmObjId;
    const presentation_formats = sessionItem.form.presentation_formats;
    const myformsections = sessionItem.form.form_sections;   
        
    let params = (new URL(document.location)).searchParams;
    let sectionid = 1;
    let qsstepid = 0;
    let stepcount = 0;
    let stepheader = ""
    let sectionname = "";
    if (params.has("id")) {
        sectionid = parseInt(params.get("id"));
    }
    if (params.has("step")) {
        qsstepid = parseInt(params.get("step"));
    }   
    myformsections.forEach((item) => {
        if (item.section_id === sectionid) {
            sectionname = item.section_name;
            stepcount = item.steps.length;  
            if (stepcount > 0) {
                if (qsstepid === 0) {
                    // set default step if not passed as qs
                    qsstepid = 1;
                }                
            }
        }
    })
    // end of section nav header logic

    // section nav header logic
    if (sessionItem.film.submittedfinal) {
        // if film is submitted final
        // redirect to status page
        // this page should not be displayed
        window.location.href = '/status?sectionid=' + sectionid.toString();
    }

    // we need to store the required arrays in useState
    //const filmrequired = useState(requiredarray);
    //const rolesrequired = useState(requiredrolesarray);    

    const [stepid, setStepid] = useState(qsstepid);
    const section_config = ParseFormSectionConfig(myformsections, sectionid, stepid);
    const section_config_all = ParseFormSectionConfig(myformsections, sectionid, 0);
    let content_elements = section_config.content_elements;
    let content_elements_all = section_config_all.content_elements;
    let requiredarray = section_config.requiredarray;
    let requiredrolesarray = section_config.requiredrolesarray;
    let steps = section_config.steps;
    let conditional_props = section_config.conditional_props;
    let conditional_roles = section_config.conditional_roles

    if (stepcount > 0) {       
        stepheader = "Step " + stepid + " of " + stepcount;
    }
   

    // logic for displaying step navigation buttons
    let prevstep = 0;
    let nextstep = 0;
    if (stepid > 0) {
        // section is divided in steps
        // let's determine if we need to display prev and next buttons
        if (stepid > 1) {
            prevstep = stepid - 1;
        }
        if (stepid < steps.length) {
            nextstep = stepid + 1;
        }
    }
    let prevaction = [];
    let nextaction = [];
    if (prevstep > 0) {
        prevaction.push(<Button variant="outlined" sx={{ mr: 2 }} key="PrevStep" type="submit" name="PrevStep" value="Prev Step" startIcon={<NavigateBeforeIcon />}>Prev Step</Button>);
    }
    if (nextstep > 0) {
        nextaction.push(<Button variant="outlined" sx={{ ml: 2 }} key="NextStep" type="submit" name="NextStep" value="Next Step" endIcon={<NavigateNextIcon />}>Next Step</Button>);
    }


    // create a stateobj to track all the film properties values in useState for the section
    // we neeed all content elements of the section - not just the content of the stpe
    const statefilmobj = GetFormFilmObject(content_elements_all, myfilm);
    // save stateformobj in the values useState for all elements

    const [values, setValues] = useState(statefilmobj);

    // create a state array to track the role items in useState
    // we only need names at this point for displaying on the main film form
    // the rest of the data is edited at the entity level.
    // Note: refactored as a separate function getRolesArray for clarity
    const staterolesar = GetRolesArray(content_elements, myfilm);

    // save staterolesar in the roles useState
    // const [roles, setRoles] = useState(staterolesar); 

    // define the handler for the onchange of each form element
    function handleChange(evt) {
        const value = evt.target.value;
        setValues({
            ...values,
            [evt.target.name]: value
        });
    }

    // handler for checkboxes
    function handleChangeCheck(evt) {
        const value = evt.target.checked;
        setValues({
            ...values,
            [evt.target.name]: value
        });
    }

    //handler for multigrid (multiselect DD)
    function handleChangeMulti(evt) {
        // value comes as an array
        const arrayvalue = evt.target.value;
        // flatten as a csv string for saving in useState object
        const csvstring = arrayvalue.join(",");
        setValues({
            ...values,
            [evt.target.name]: csvstring
        });
    }

    // handler 1 for dropdown plus other textbox
    function handleChangeDDOSel(evt) {
        const value = evt.target.value;
        setValues({
            ...values,
            [evt.target.name]: value
        });
    }

    // hander 2 for dropdown plus other textbox
    function handleChangeDDOText(evt) {
        const value = "Other: " + evt.target.value;
        const targetname = evt.target.name.replace("otherbox", "");
        setValues({
            ...values,
            [targetname]: value
        });
    }

    // handler for multicheck controls    
    function handleChangeTyped(targetname, targetvalue) {
        setValues({
            ...values,
            [targetname]: targetvalue
        });
    }

    // handler for multicheck controls
    function handlePrimary(rolegroup, mainid, secid, isprimary) {
        // get the film rolePrimaries if present
        let rolePrimaries = [];
        if (values["rolePrimaries"]) {
            rolePrimaries = values["rolePrimaries"];
        }
        if (isprimary) {
            // a new primary has been selected
            const newprimary = { "roleIds": rolegroup, "mainId": mainid, "secId": secid };
            rolePrimaries = UpdateRolePrimaries(rolePrimaries, newprimary, false);

        } else {
            // a primary has been de-selected
            const newprimary = { "roleIds": rolegroup, "mainId": mainid, "secId": secid };
            rolePrimaries = UpdateRolePrimaries(rolePrimaries, newprimary, true);
        }
        setValues({
            ...values,
            "rolePrimaries": rolePrimaries
        });
    }

    async function SaveFilmToApi(filmobj) {
        //Save Film using ApiClient
        let msg = "";
        const credentials = sessionItem.accesstoken;
        const saverespo = await SaveFilm(credentials, filmobj.filmObjId, filmobj);
        if (saverespo.IsError) {
            // Handle error
            msg = "Operation Error: " + saverespo.Message;
            //setOpMessage("Operation Error: " + saverespo.Message);
            //setSbOpen(true);
        } else {
            msg = "Record Updated."
            //setOpMessage("Record Updated");
            //setSbOpen(true);
        }
        return msg;
    }

    //function GetStepValues(stepid) {
    //    const myformsections = sessionItem.form.form_sections;
    //    const section_config = ParseFormSectionConfig(myformsections, props.section_id, stepid);
    //    const statefilmobj = GetFormFilmObject(section_config.content_elements, myfilm);
    //    return statefilmobj;
    //}

    function saveSnapshot() {
        // refresh film obj 
        // get film from session
        let curfilm = sessionItem.film;
        // loop values.statformobj properties
        // update curfilm with form values
        for (const [key, value] of Object.entries(values)) {
            curfilm[key] = value;
        }
        // special case for rolePrimaries?
        curfilm.rolePrimaries = values["rolePrimaries"];
        // save updated film to sessionItem
        sessionItem.film = curfilm;       
        setSession(sessionItem);
    }

    const handleSubmit = async e => {

        e.preventDefault();
        
        // get film from session
        let curfilm = sessionItem.film;
        // loop values.statformobj properties
        // update curfilm with form values
        for (const [key, value] of Object.entries(values)) {
            curfilm[key] = value;
        }
        // special case for rolePrimaries?
        curfilm.rolePrimaries = values["rolePrimaries"];
        // save updated film to sessionItem
        sessionItem.film = curfilm;

        // handle API update action
        const apimsg = await SaveFilmToApi(curfilm);
        setOpMessage(apimsg);
        setSbOpen(true);

        // reset dashboardrefresh so it reloads next time
        sessionItem.dashboardrefresh = true;
        // save updated sessionItem
        setSession(sessionItem);

        // check if button press was a step next/prev
        // if so navigate to required step

        if (e.nativeEvent.submitter.name === "PrevStep") {
            const prevstepid = stepid - 1;


            // this should refresh the page to the prev step
            setStepid(prevstepid);
            //const statefilmobj = GetStepValues(prevstepid);
            //setValues(statefilmobj);
            navigate("/section?id=" + sectionid + "&step=" + prevstepid, { replace: true });

        }

        if (e.nativeEvent.submitter.name === "NextStep") {
            const nextstepid = stepid + 1;


            // this should refresh the page to the next step
            setStepid(nextstepid);
            //const statefilmobj = GetStepValues(nextstepid);
            //setValues(statefilmobj);
            navigate("/section?id=" + sectionid + "&step=" + nextstepid, { replace: true });
        }

    }

    function handleSnackBarClose() {
        if (!opMessage.includes("Operation Error")) {
            // decide if we want to highlight the error.
        }
        setOpMessage("");
        setSbOpen(false);
    }

    const sbaction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackBarClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <form onSubmit={handleSubmit}>
            <MainNav />
            <h2>Section {sectionid}: {sectionname}</h2>
            <h4>{stepheader}</h4>
            <div>
                {/*{formElements}*/}
                <FormContentList
                    conditional_props={conditional_props}
                    conditional_roles={conditional_roles}
                    content_elements={content_elements}
                    handleChange={handleChange}
                    handleChangeMulti={handleChangeMulti}
                    handleChangeDDOSel={handleChangeDDOSel}
                    handleChangeDDOText={handleChangeDDOText}
                    handleChangeCheck={handleChangeCheck}
                    handleChangeTyped={handleChangeTyped}
                    handlePrimary={handlePrimary}
                    presentation_formats={presentation_formats}
                    requiredarray={requiredarray}
                    requiredrolesarray={requiredrolesarray}
                    staterolesar={staterolesar}
                    filmid={filmid}
                    section_id={sectionid}
                    values={values}
                    saveSnapshot={saveSnapshot}
                />
            </div>
            <Box
                sx={{
                    width: 800,
                    maxWidth: 900,
                    alignItems: 'left',
                    marginTop: 2,
                    marginBottom: 2
                }}
            >
                {prevaction}
                <Button variant="outlined" type="submit" value="Save" startIcon={<SaveIcon />}>Save</Button>
                {nextaction}
            </Box>
            <Snackbar
                open={sbopen}
                autoHideDuration={3000}
                onClose={handleSnackBarClose}
                message={opMessage}
                action={sbaction}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            />
        </form>
    )
}

import React from 'react';
import './DashBoardSection.css';

export default function DashBoardSection(props) {

    let statusClass = "label-incomplete";
    let statusText = "Incomplete";
    let dsectionWrapper = "dashboard-section-incomplete";
    let linkcopy = "Edit Section";
    let linkurl = "/section?id=" + props.sectionid;
    if (props.completed) {
        statusClass = "label-complete";
        statusText = "Complete";
        dsectionWrapper = "dashboard-section-complete";
        linkcopy = "Update Section";
    }
    const isfinal = props.final;
    if (isfinal) {
        linkurl = "/status?sectionid=" + props.sectionid;
        linkcopy = "View Section"
    }

    return (
        <div className={dsectionWrapper}>
            <a href={'/section?id=' + props.sectionid}><h2>Section {props.sectionid}: {props.sectionname}</h2></a>
            <p>
                {props.dashboard_copy}
            </p>
            <p className="section-status">
                Status: <a href={'/status?sectionid=' + props.sectionid}><span className={statusClass}>{statusText}</span></a>
            </p>
            <p className="section-footer">
                <a href={linkurl} className="pull-right btn btn-default">{linkcopy}</a>
            </p>
            <br/>
        </div >        
    )
}
import React, { useState }from 'react';
import useSession from '../../useSession';
import Box from '@mui/material/Box';
//import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import './AgreeTerms.css';
import { SaveTermsRead } from '../Helpers/Helpers';

export default function AgreeTerms() {

    const { sessionItem } = useSession(); 
    const [sbopen, setSbOpen] = useState(false);
    const [opMessage, setOpMessage] = useState("");      

    function ValidateConfirm() { 
        SaveTermsRead(sessionItem.film.filmObjId, 43200);
        // redirect to the home page            
        window.location.href = '/';
    }

    function handleSnackBarClose() {       
        setOpMessage("");
        setSbOpen(false);       
    }

    const sbaction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackBarClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );    

    return (
        <Box sx={{ minWidth: 800, bgcolor: '#F0F0F0', p: 1}}>
            {/*<h2>Publicity contacts for the film: {sessionItem.film.engTitle}</h2>  */}          
            <Box>
                <article>
                    <h2>CONFIRM FILM PUBLICISTS</h2>
                    <p>
                        Upon clicking "Confirm Information" you will be taken to a page with the
                        publicity contact information initially submitted at the film invitation stage.
                    </p>                               
                </article>
                <article>
                    <h2>WHY SHOULD YOU CONFIRM YOUR PUBLICISTS?</h2>
                    <ul>
                        <li>
                            The publicist contact information you supply here for each territory will be shared with the
                            TIFF accredited media list. This gives accredited media the correct contacts for your film.
                        </li>
                        <li>
                            It will provide context for the Publicist Registration form (to be sent as a follow up).
                        </li>
                    </ul> 
                    <p>
                        You should review and confirm the information by <span className="boldtext">Monday, August 12,
                            2024 at 8:00pm ET/5:00pm PT</span>. Please note: The sooner this information
                        is confirmed, the sooner we will reach out regarding registration
                        for Publicist passes.
                    </p>
                    <p>
                        This form may be updated and saved several times, but you must click SUBMIT to finalize it
                        before the deadline. However, <span className="boldtext">once it is submitted it is considered final
                        and you may not resubmit changes through the system.</span>
                    </p>
                    <p>
                        Please note: You must mark one of the publicists as the Primary publicist. You won't
                        be able to submit your completed form without doing this.
                    </p>
                </article>               
                <article>                   
                    <div>                        
                        <div>
                            <Button variant="contained" sx={{ marginBottom: 1}} name="addrolebt" onClick={ValidateConfirm} >
                                Confirm Information
                            </Button>
                        </div>
                        <div>
                            It is recommended to use the same browser when completing the Film Registration Form.
                        </div>
                    </div>
                </article> 
                <Snackbar
                    open={sbopen}
                    autoHideDuration={3000}
                    onClose={handleSnackBarClose}
                    message={opMessage}
                    action={sbaction}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                />
            </Box>
        </Box>

    );
}
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box'; 
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import useSession from '../../useSession';
import { GetRoleForm, SaveRole } from '../ApiClient/ApiClient';
import { ParseEntityOptions, GetCurrentRoleGridData, RemoveRoleRecord } from '../Helpers/Helpers';
import MuiAlert from '@mui/material/Alert';
import FormHelperText from '@mui/material/FormHelperText';
import MainNav from '../MainNav/MainNav';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ParseQs(qsname) {
    let params = (new URL(document.location)).searchParams;
    let qsvalue = "";
    if (params.has(qsname)) {
        qsvalue = params.get(qsname);
    }
    return qsvalue;
} 

export default function AddContact() {      

    const [sbopen, setSbOpen] = useState(false);
    const [opMessage, setOpMessage] = useState("");
   
    const [secEntity, setSecEntity] = useState(""); 
    const { sessionItem, setSession } = useSession();
    const [formData, setFormData] = useState();
    const [callcount, setCallCount] = useState(0);
    const [recordadded, setRecordAdded] = useState("");


    if (sessionItem.film.submittedfinal) {
        // if film is submitted final
        // redirect to dashboard page
        // this page should not be displayed
        window.location.href = '/';
    }
    
    let secrequired = true;     
    const rolestring = ParseQs("id"); 

    const sectionid = ParseQs("sectionid");   
    const credentials = sessionItem.accesstoken;   

    // preset mainid from qs   
    const mainidqs = ParseQs("mainid");  
    const mainid = parseInt(mainidqs)    

    const [mainEntity, setMainEntity] = useState(mainidqs);

    // code below handles async call to API
    // the array [] passed as the second param makes sure this is executed only once 
    // since those values should not change during the screen actions.
    useEffect(() => {
        let roleid = 0;
        const rolestring = ParseQs("id");
        if (rolestring) {
            roleid = parseInt(rolestring);
        }
        const credentials = sessionItem.accesstoken;
        const filmid = sessionItem.film.filmObjId;
        const formid = sessionItem.form.form_id; 
        const getFormData = async () => {
            // set variable to make sure we don't run this again when the page is refreshed
            //setApiCalled(true);
            const respo = await GetRoleForm(credentials, formid, roleid, filmid);
            if (respo.role_name) {
                setFormData(respo);                 
            } else {
                // handle error             
                setOpMessage("Unexpected error while loading the data for this page.")
                setSbOpen(true);
            }
        };
        getFormData();
    }, [sessionItem.accesstoken, sessionItem.film.filmObjId, sessionItem.form.form_id, callcount]);    
    // end of async call logic
           
    function onChangeSec(evt) {
        const value = parseInt(evt.target.value);
        setSecEntity(value);
    }

    const navigate = useNavigate();
    function RedirectBack() {
        let sectionid = ParseQs("sectionid");
        if (!sectionid) {
            //fallback to 1 for now?
            sectionid = 1;
        }
        navigate("/section?id=" + sectionid);
    }

    function handleSnackBarClose() {
        if (!opMessage.includes("Operation Error")){
            // decide if we want to highlight the error.
        }  
        setOpMessage("");
        setSbOpen(false); 
    }

    const sbaction = (
        <React.Fragment>            
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackBarClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    ); 

    // handle Add action    
    async function AddToRecord() {
        const filmid = sessionItem.film.filmObjId; 
        let roleid = 0;
        const rolestring = ParseQs("id");
        if (rolestring) {
            roleid = parseInt(rolestring);
        }
        let sectype = "";
        let max_count = 0;
        if (formData) {
            if (formData.sec_type) {
                sectype = formData.sec_type;
            }
            if (formData.max_count) {
                max_count = formData.max_count;
            }
        }
        // determine if drop down selections are valid
        let isvalid = false;
        let errMsg = "Missing left panel selection. "
        if (formData) {
            if (formData.main_type) {
                if (formData.main_type === "company") {
                    errMsg = "Missing company selection. "
                } else {
                    errMsg = "Missing contact selection. "
                }
                if (formData.role_id) {
                    if (formData.role_id === 17) {
                        errMsg = "Missing director selection. "
                    }
                }      
            }
        }
        if (mainEntity > 0) {
            if (sectype === "") {
                isvalid = true;
                errMsg = "";
            } else {
                if (secrequired) {
                    if (secEntity > 0) {
                        isvalid = true;
                    } else {
                        errMsg = " Missing right panel selection. Both fields need to be filled out."
                        if (formData) {
                            if (formData.sec_type) {
                                if (formData.sec_type === "company") {
                                    errMsg = "Missing company selection. "
                                } else {
                                    errMsg = "Missing contact selection. "
                                    // special case for director liaison
                                    if (formData.role_id) {
                                        if (formData.role_id === 17) {
                                            errMsg = "Missing liaison selection. "
                                        } 
                                    }                                    
                                }
                            }
                        }
                    }
                }
                else {
                    // if sec is optional it does not matter if we have a selection
                    // or not
                    isvalid = true;
                }                
            }
        }        
        if (isvalid) {
            const roleobj = {
                "roleId": roleid,
                "filmId": filmid,
                "mainEntityId": mainEntity,
                "otherEntityId": secEntity
            }
            const addrespo = await SaveRole(credentials, roleobj);
            if (addrespo.IsError) {
                // handle error
                // Handle error
                setOpMessage("Operation Error: " + addrespo.Message);
                setSbOpen(true);
            } else {
                let addedname = "";
                if (addrespo.RoleData) {
                    if (addrespo.RoleData.otherEntity) {
                        addedname = addrespo.RoleData.otherEntity.firstName + " " + addrespo.RoleData.otherEntity.lastName;
                    }
                }
                setRecordAdded(addedname);

                // update sessionState with response role
                // delete curret record   
                const roleid = parseInt(rolestring);               
                const updatedroles = RemoveRoleRecord(sessionItem.film.rolesList, roleid, mainid, 0).slice();
                sessionItem.film.rolelist = updatedroles.slice();   
                sessionItem.film.rolesList.push(addrespo.RoleData);                                         

                // sessionItem.film.rolesList = UpdateRoleItemContact(sessionItem.film.rolesList, rolestring, mainid, addrespo.RoleData);    
                // update rolePrimaries from API response
                if (addrespo.rolePrimaries) {
                    sessionItem.film.rolePrimaries = addrespo.rolePrimaries;
                }

                // reset dashboardrefresh variable to pull from the API next time user navigates to dahsboard
                sessionItem.dashboardrefresh = true;   
                
                // save sessionItem
                setSession(sessionItem); 
                // flag in case role allows only one entry
                // this is the the common use case for publicists
                let snackbarmessage = addrespo.Message;
                if (max_count === 1) {
                    snackbarmessage = snackbarmessage + " Note: Only one contact is accepted for this field.";
                }
                setOpMessage(snackbarmessage);
                setSbOpen(true);
                // trigger API call to refresh data on the page
                setCallCount(callcount + 1);
                // remove current selection from dropdowns
                // those options will be removed from the list
                setMainEntity("0");
                setSecEntity("0");
            }

        } else {
            setOpMessage("Operation Error, invalid Selection: " + errMsg);
            setSbOpen(true);
        }        
    }     

    let mainentityname = "";
    // parse main entity name    
    if (formData) {
        if (formData.role_entries) {
            formData.role_entries.forEach((roleentry) => {
                if (roleentry.mainEntity) {
                    if (roleentry.mainEntity.entityObjId.toString() === mainidqs) {
                        mainentityname = roleentry.mainEntity.firstName + " " + roleentry.mainEntity.lastName;
                    }
                }
            })            
        }
    }    
    // parse sec entity options
    let secddoptions = [];
    let respso = []
    if (formData) {
        if (formData.sec_options) {
            if (Array.isArray(formData.sec_options)) {
                if (formData.sec_options.length > 0) {
                    respso = ParseEntityOptions(formData.sec_options);
                }
            }
            if (respso) {
                respso.forEach((item) => {
                    const oitem = <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>;
                    secddoptions.push(oitem);
                })
            }
        }        
    } 

    // get form headers and messages    
    let secentityAddUrl = "/entity?roleid=" + rolestring + "&sectionid=" + sectionid + "&filmid=" + sessionItem.film.filmObjId+"&mainsec=sec";
    // add current selection to entity add urls so we can rebuild current selections
    // when navigating back from entity page
    if (mainEntity !== "0" && mainEntity !== "") {       
        secentityAddUrl += "&mainid=" + mainEntity;
    }
    if (secEntity !== "0" && secEntity !== "") {       
        secentityAddUrl += "&secid=" + secEntity;
    }
    // add info to allow entity screen to navigate back to this page
    secentityAddUrl +="&backref=ac"
    let formheader = "";
    let formmessage = "";
    //let maincaption = "";
    
    let seccaption = "";
    if (formData) {
        if (formData.form_header) {
            formheader = formData.form_header;
        }
        if (formData.message) {
            formmessage = <Alert severity="warning">{formData.message}</Alert>;
        }
        if (formData.sec_type === "") {
            
        } else {
            if (formData.role_id === 17) {
                // special use case for director                
                seccaption = "Liaison";
            } else {               
                seccaption = formData.sec_type.replace("person", "contact");                
            }
        }
    }    

    // check max count to see if we reached the limit
    // if so hide the add buttons
    let isfull = false;
    let roledata = [];
    if (formData) {
        if (formData.role_entries) {
            roledata = GetCurrentRoleGridData(formData.role_entries);
        }
        if (formData.max_count) {
            if (formData.max_count === 1 && roledata.length > 0) {
                isfull = true; 
            }    
        }        
    }

    let showdd = true;
    if (recordadded !== "") {
        showdd = false;
    }
   
    const sechelpercopy = "Select an entry from the above menu. If you want to add a new " + seccaption.toLowerCase() + " not listed above, click the button below to proceed."

    return (
        <Grid container spacing={1}> 
            <Grid item xs={12}>
                <MainNav /> 
            </Grid>
            <Grid item xs={12}>
                <h3>
                    {formheader}                  
                </h3>
            </Grid>            
            <Grid item xs={6}>               
                Director: {mainentityname}          
            </Grid>           
            <Grid item xs={6}>
                {showdd &&
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <Select
                            id='secentitysel'
                            name='secentitysel'
                            value={secEntity}
                            onChange={onChangeSec}
                        >
                            {secddoptions}
                        </Select>
                        <FormHelperText sx={{ marginLeft: 1 }}>{sechelpercopy}</FormHelperText>
                        {!isfull &&
                            <Button variant="outlined" size="small" sx={{ marginTop: 2 }} name="addsecentity" href={secentityAddUrl} startIcon={<AddIcon />}>
                                New {seccaption} Not Listed Above
                            </Button>
                        }
                    </FormControl>
                }
                {!showdd && "Liaison: " + recordadded}
            </Grid>
            <Grid item xs={12}>
                    <Box component="span" display="flex" justifyContent="space-between">
                        <Button variant="outlined" color="secondary" name="navbackrolebt" onClick={RedirectBack} startIcon={<ArrowBackIcon />}>
                            Back
                        </Button>
                    {!isfull && showdd &&
                    <Button variant="contained" name="addrolebt" onClick={AddToRecord} startIcon={<AddIcon />}>
                            Add Selected {seccaption} to Record
                    </Button>
                    }                    
                    {isfull &&
                        <p>Only one record is allwed for this credit/role</p>
                        }
                    </Box>
            </Grid>                      
            <Grid item xs={12}>
                {formmessage}
            </Grid>
            <Snackbar
                open={sbopen}
                autoHideDuration={3000}
                onClose={handleSnackBarClose}
                message={opMessage}
                action={sbaction}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            />
        </Grid>
    )
}
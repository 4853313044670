import React, { useState } from 'react';
import './FormElements.css';
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import { ParseHelperCopy } from '../Helpers/Helpers';

export default function DropDownOther(props) { 

    const [showother, setShowOther] = useState(false);

    let seloptions = [];

    let poptions = props.options;

    let othervalue = "";
    if (props.value.includes("Other")) {
        othervalue = props.value.replace("Other", "").replace(": ","");
    } 

    let selvalue = props.value;
    if (props.value.includes("Other")) {
        selvalue = "Other";
    }

    // add empty select at the top
    let emptysel = "";
    if (props.custom_props) { 
        if (props.custom_props.emptysel) {
            emptysel = props.custom_props.emptysel;
        }    
    } 
    const defitem = <MenuItem key="default" value={emptysel}> -Select- </MenuItem>;
    seloptions.push(defitem);

    if (poptions) {
        props.options.forEach((item) => {
            let selected = ""
            let itemvalue = item;
            let optiontext = item
            let optionvalue = item
            if (itemvalue.includes(":")) {
                // split value and text if value is formatted as "text:value"
                const myArray = itemvalue.split(":");
                optiontext = myArray[0];
                optionvalue = myArray[1];
            }
            if (props.value === { optionvalue }) {
                selected = "selected";
            } 

            const oitem = <MenuItem key={item} {...selected} value={optionvalue}>{optiontext}</MenuItem>;
            seloptions.push(oitem);
        })
    }

    // handle hide/show textbox for other details
    if (props.value.includes("Other")) {
        if (!showother) {
            setShowOther(true);
        }
    }
    else {
        if (showother) {
            setShowOther(false);
        }
    }

    
    // todo: review code below to execute only if props.options does not have an "other" option.

    // add Other option at the bottom of the list
    //let otherselected = "";
    //if (props.value.includes("Other")) {
    //    otherselected = "selected"
    //}
    //const otheritem = <MenuItem key="Other" {...otherselected} value="Other">Other</MenuItem>;
    //seloptions.push(otheritem);

    // handle required label
    let labelreq = "";
    if (props.req) {
        labelreq = " *";
    }

    let labelname = props.name + "label";

    //handle TexField id and name
    const texfieldid = props.name + "otherbox";     

    // logic for handling helper copy before or after main control    
    let helpercopy_pre = "";
    let helpercopy_post = "";
    if (props.helper) {
        const helperobj = ParseHelperCopy(props.helper);
        if (helperobj.pre.length > 0) {
            helpercopy_pre = <span dangerouslySetInnerHTML={{ __html: helperobj.pre }} ></span>;
        }
        if (helperobj.post.length > 0) {
            helpercopy_post = <span dangerouslySetInnerHTML={{ __html: helperobj.post }} ></span>;
        }
    }
    // end of helper copy handling

    return (
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <Box sx={{ my: 1 }}>
                    <label htmlFor={props.name}>{props.label} </label>
                    <Box component="span" sx={{ color: 'red' }}>
                    {labelreq}
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={8}>
                <div>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                        {helpercopy_pre}
                        <InputLabel id={labelname}></InputLabel>
                        <Select
                            labelId={labelname}
                            id={props.name}
                            name={props.name}
                            value={selvalue}
                            onChange={props.onChangeDD}
                        >
                            {seloptions}
                        </Select>
                        <FormHelperText sx={{ marginLeft: 0 }}>{helpercopy_post}</FormHelperText>
                    </FormControl>
                </div>
                <div>
                    {showother ? <TextField label="Enter Details" id={texfieldid} name={texfieldid} onChange={props.onChangeOther} value={othervalue} /> : null}                    
                </div>
            </Grid>
        </Grid >
    );
}
// Parses the selected from section configuration items required for rendering
export function ParseFormSectionConfig(form_sections, section_id, step_id) {
    const section_config = {};
    let content_elements = [];
    let requiredarray = [];
    let requiredrolesarray = [];
    let steps = [];
    let conditional_props = [];
    let conditional_roles = [];
    // determine the required properties, roles and steps
    form_sections.forEach((item) => {
        // locate the section config
        if (item.section_id === section_id) {
            // check if content_elements is in the root or in the steps array
            if (item.content_elements.length) {
                // get content elements
                content_elements = item.content_elements;
            }
            else {
                // get step passed as part of props
                // parse to get the content elements                
                item.steps.forEach((sitem) => {
                    if (step_id === 0) {
                        // get all content elements regardless of step
                        sitem.content_elements.forEach((celement) => {
                            content_elements.push(celement);
                        })                        
                    }
                    else {
                        if (sitem.step_id === step_id) {
                            content_elements = sitem.content_elements;
                        }
                    }                    
                })
                // set local steps array to steps configured in this section
                steps = item.steps;
            }
            // parse the required properties for this section
            if (item.req_film_properties.length) {
                requiredarray = item.req_film_properties;
            }
            // parse the required roles for this section
            if (item.req_roles.length) {
                requiredrolesarray = item.req_roles;
            }
            // parse the conditional props and roles
            // these are props and roles that should hide/show
            // depending on another prop value
            if (item.conditional_props.length) {
                conditional_props = item.conditional_props;
            }
            if (item.conditional_roles.length) {
                conditional_roles = item.conditional_roles;
            }
        }
    })
    section_config.content_elements = content_elements;
    section_config.requiredarray = requiredarray;
    section_config.requiredrolesarray = requiredrolesarray;
    section_config.steps = steps;
    section_config.conditional_props = conditional_props;
    section_config.conditional_roles = conditional_roles;
    return section_config;
}


// Parses the content_elements and films objects
// and returns an array of objects containing the entity names and ids 
export function GetRolesArray(content_elements, film) {
    let staterolesar = [];
    if (Array.isArray(content_elements)) {
        if (content_elements.length > 0) {
            content_elements.forEach((citem) => {
                // do this only for film props, skip role content elements
                if (citem.object_type === "role") {
                    if (citem.object_property.length > 0) {
                        const rolearray = film.rolesList.filter(function (roleitem) {
                            return roleitem.roleId === citem.object_property;
                        });
                        if (rolearray.length > 0) {
                            rolearray.forEach((roleitem) => {
                                let roleitemkey = "";
                                let mainname = "";
                                let mainid = 0;
                                //let mainisperson = false;
                                if (roleitem.mainEntity) {
                                    mainid = roleitem.mainEntity.entityObjId;
                                    roleitemkey = roleitem.mainEntity.entityObjId.toString();
                                    if (roleitem.mainEntity.isPerson) {
                                        //mainisperson = true;
                                        mainname = roleitem.mainEntity.firstName + " " + roleitem.mainEntity.lastName;
                                    } else {
                                        mainname = roleitem.mainEntity.companyName;
                                    }
                                }
                                let secname = "";
                                let secid = 0;
                                if (roleitem.otherEntity) {
                                    secid = roleitem.otherEntity.entityObjId;
                                    roleitemkey += "-" + roleitem.otherEntity.entityObjId.toString();
                                    if (roleitem.otherEntity.isPerson) {
                                        secname = roleitem.otherEntity.firstName + " " + roleitem.otherEntity.lastName;
                                    } else {
                                        secname = roleitem.otherEntity.companyName;
                                    }
                                } else {
                                    roleitemkey += "-0";
                                }                               
                                let primaryrec = false;                               
                                const roleitemobj = { "key": roleitemkey, "mainname": mainname, "mainid": mainid, "secname": secname, "secid": secid, "roleid": roleitem.roleId, "primary": primaryrec };
                                staterolesar.push(roleitemobj);
                            })
                        }
                    }
                }
            })
        }
    }    
    return staterolesar;
}


// creates the base film object that is used to track useState in main form
export function GetFormFilmObject(content_elements, film) {
    let statefilmobj = {};
    if (Array.isArray(content_elements)) {
        if (content_elements.length > 0) {
            content_elements.forEach((citem) => {
                let myinputvalue = ""
                // do this only for film props, skip role content elements
                if (citem.object_type === "film") {
                    if (citem.object_property.length > 0) {
                        if (film.hasOwnProperty(citem.object_property)) {
                            if (film[citem.object_property]) {
                                myinputvalue = film[citem.object_property];
                            }
                            else {
                                // use case for false boolean properties
                                if (film[citem.object_property] === false) {
                                    myinputvalue = false;
                                }
                            }
                            // todo: reset conditionals based on rule?
                        }
                        statefilmobj[citem.object_property] = myinputvalue;
                    }
                }
            })
        } 
    }
    // special case for rolePrimaries, this is not connected to a form content element
    // so we add it manually to object that will be used used in useState of the form
    if (film.hasOwnProperty("rolePrimaries")) {
        if (film["rolePrimaries"]) {
            statefilmobj["rolePrimaries"] = film.rolePrimaries;
        } else {
            statefilmobj["rolePrimaries"] = [];
        }
    }
    // hack for PA primaries are save in base film object
    if (film.hasOwnProperty("primaryPublicistId")) {
        statefilmobj["primaryPublicistId"] = film.primaryPublicistId;
    }
    if (film.hasOwnProperty("primaryPublicistCompanyId")) {
        statefilmobj["primaryPublicistCompanyId"] = film.primaryPublicistCompanyId;
    }


    return statefilmobj;
}

// creates the base entity object that is used to track useState in main form
export function GetFormEntityObject(content_elements, entity) {
    let stateentityobj = {};
    if (Array.isArray(content_elements)) {
        if (content_elements.length > 0) {
            content_elements.forEach((citem) => {
                let myinputvalue = ""
                // do this only for film props, skip role content elements
                if (citem.object_type === "entity") {
                    if (citem.object_property.length > 0) {
                        if (entity.hasOwnProperty(citem.object_property)) {
                            if (entity[citem.object_property]) {
                                myinputvalue = entity[citem.object_property];
                            }                            
                        }
                        stateentityobj[citem.object_property] = myinputvalue;
                    }
                }
            })
        }
    }
    return stateentityobj;
}

export function GetPresentationFormatOptions(usecase, presentation_formats, filmstateobj) {
    let ddoptions = []; 
    switch (usecase) {
        case "form.presentation_formats":
            ddoptions = ParsePresentationFormats(presentation_formats);
            break;
        case "form.presentation_formats.aspect_ratios":
            if (filmstateobj.presentationFormat) {
                ddoptions = GetAspectRatios(filmstateobj.presentationFormat, presentation_formats);
            }
            break;
        case "form.presentation_formats.sound_formats":
            if (filmstateobj.presentationFormat) {
                ddoptions = GetSoundFormats(filmstateobj.presentationFormat, presentation_formats);
            }
            break;
        default:
            break;
    }
    return ddoptions;
}

// returns an arry of presentation format names from the list of objects
// provided by the API
export function ParsePresentationFormats(presentation_formats) {
    let formats = [];
    presentation_formats.forEach((pformat) => {
        formats.push(pformat.format_name);
    })
    return formats;
}

// parse a list of presentation formats
// and returns the list of sound formats for the selected presentation
export function GetSoundFormats(selected_format, format_list) {
    let result = [];
    if (format_list.length > 0) {
        const resarray = format_list.filter(function (fitem) {
            return fitem.format_name === selected_format;
        });
        if (resarray.length > 0) {
            result = resarray[0].sound_formats.slice();
        }
    }
    return result;
}

// parse a list of presentation formats
// and returns the list of aspect ratio options for the selected presentation
export function GetAspectRatios(selected_format, format_list) {
    let result = [];
    if (format_list.length > 0) {
        const resarray = format_list.filter(function (fitem) {
            return fitem.format_name === selected_format;
        });
        if (resarray.length > 0) {
            result = resarray[0].aspect_ratios.slice();
        }
    }
    return result;
}

// parse custom_rules and updates current_required list, based on the property_value of film property_name
// custom_rules, current_required and form_required are arrays
// return an array of required property_name strings
export function CustomRulesForReqFilmProps(custom_rules, current_required, form_required, property_name, property_value) {
    if (Array.isArray(custom_rules) && Array.isArray(current_required) && Array.isArray(form_required)) {
        // detect if any of the rules is "linked"
        // these are the rules that affect the list fo required properties
        let rulefound=false;
        custom_rules.forEach((crule) => {
            if (crule.rule_type === "linked") {
                // check if the rule condition is a match
                if (crule.object_property === property_name) {                    
                    if (property_value===""){
                        // reset current required to form default
                        // that is the base rule for empty values
                        current_required=form_required.slice();
                        rulefound=true;
                    }
                    else{
                        // property name is match
                        // update current_required if extra req properties are not there already
                        if (crule.object_value.includes(property_value)) {
                            // we must reset current_required to form_required and add new props
                            // listed in the rule
                            current_required = form_required.slice();
                            // add required properties to current required if not present
                            if (Array.isArray(crule.linked_req_properties)) {
                                crule.linked_req_properties.forEach((linkedprop) => {
                                    // Note: linked props starting with Role are not film props
                                    if (!current_required.includes(linkedprop) && !linkedprop.includes("Role")) {
                                        current_required.push(linkedprop)
                                    }
                                })
                            }
                            rulefound=true;
                        } 
                    }                    
                } 
            }            
        })
        // if no rule is found for property value
        // reset current list to default list
        if (!rulefound){
            current_required=form_required.slice();
        }
    }
    return current_required;
}

// parse custom_rules and updates current_required roles list, based on the property_value of film property_name
// custom_rules, current_required and form_required are arrays
// return an array of required roles strings
export function CustomRulesForReqRoles(custom_rules, current_reqroles, form_reqroles, property_name, property_value) {
    if (Array.isArray(custom_rules) && Array.isArray(current_reqroles) && Array.isArray(form_reqroles)) {
        // detect if any of the rules is "linked"
        // these are the rules that affect the list fo required properties
        let rulefound=false;
        custom_rules.forEach((crule) => {
            if (crule.rule_type === "linked") {
                // check if the rule condition is a match
                if (crule.object_property === property_name) {
                    if (property_value===""){
                        // reset current required to form default
                        // that is the base rule for empty values
                        current_reqroles=form_reqroles.slice();
                        rulefound=true;
                    }else{
                        if (crule.object_value.includes(property_value)) {
                            // we must reset current_required to form_required and add new props
                            // listed in the rule
                            current_reqroles = form_reqroles.slice();
                            // add required properties to current required if not present
                            if (Array.isArray(crule.linked_req_properties)) {
                                crule.linked_req_properties.forEach((linkedprop) => {
                                    if (linkedprop.includes("Role")) {
                                        const roleid = linkedprop.replace("Role:", "");
                                        const roleidint = parseInt(roleid)
                                        if (!current_reqroles.includes(roleidint)) {
                                            current_reqroles.push(roleidint)
                                        }
                                    }                                
                                })
                            }
                            rulefound=true;
                        }
                    }                    
                }
            }
        })
        // if no rule is found for property value
        // reset current list to default list
        if (!rulefound){
            current_reqroles = form_reqroles.slice();
        }
    }
    return current_reqroles;
}




// determine if any of the film_properties must be hidden by default, based on custom_rules 
// this should remove from film_properties any values found as "linked"
// the linked properties must be added/removed by running UpdateSectionFilmShowHideArray
export function GetSectionFilmShowArray(film_properties, custom_rules) {  
    custom_rules.forEach((crule) => {
        if (crule.rule_type === "linked") {
            if (Array.isArray(crule.linked_req_properties)) {
                crule.linked_req_properties.forEach((linkedprop) => {
                    const index = film_properties.indexOf(linkedprop);
                    if (index > -1) {
                        film_properties.splice(index, 1);
                    }
                })
            }
        }
    })
    return film_properties;
}

// determine if any of the film roles must be hidden by default, based on custom_rules 
// this should remove from film roles any values found as "linked"
// the linked roles must be added/removed by running UpdateSectionRolesShowHideArray
export function GetSectionRolesShowArray(role_list, custom_rules) {   
    custom_rules.forEach((crule) => {
        if (crule.rule_type === "linked") {
            if (Array.isArray(crule.linked_req_properties)) {
                crule.linked_req_properties.forEach((linkedprop) => {
                    if (linkedprop.includes("Role")) {
                        const roleid = linkedprop.replace("Role:", "");
                        const roleidint = parseInt(roleid)
                        const index = role_list.indexOf(roleidint);
                        if (index > -1) {
                            role_list.splice(index, 1);
                        }
                    }                    
                })
            }
        }
    })
    return role_list;
}

// updates list of hide/show film properties based on custom rules and value of selected film property
// that triggers a rule
export function UpdateSectionFilmShowArray(film_show_array, custom_rules, req_film_properties, property_name, property_value) {
    if (Array.isArray(custom_rules) && Array.isArray(film_show_array) && Array.isArray(req_film_properties)) {
        custom_rules.forEach((crule) => {
            if (crule.rule_type === "linked") {
                if (crule.object_property === property_name) {
                    if (crule.object_value.includes(property_value)) {
                        // add properties to film_show_array if not there
                        if (Array.isArray(crule.linked_req_properties)) {
                            crule.linked_req_propertiesforEach((linkedprop) => {
                                if (!film_show_array.includes(linkedprop) && !linkedprop.includes("Role")) {
                                    film_show_array.push(linkedprop)
                                }
                            })
                        }
                    } else {
                        // remove properties from film_show_array if there
                        // and not part of default req_film_properties
                        if (Array.isArray(crule.linked_req_properties)) {
                            crule.linked_req_propertiesforEach((linkedprop) => {
                                if (film_show_array.includes(linkedprop) && !req_film_properties.includes(linkedprop)) {
                                    // remove linkedprop value from current_required array
                                    const index = film_show_array.indexOf(linkedprop);
                                    if (index > -1) {
                                        film_show_array.splice(index, 1);
                                    }
                                }
                            })
                        }
                    }
                }
            }
        })
    }
    return film_show_array;
}

// updates list of hide/show film roles based on custom rules and value of selected film property
// that triggers a rule
export function UpdateSectionRolesShowArray(roles_show_array, custom_rules, req_roles, property_name, property_value) {
    if (Array.isArray(custom_rules) && Array.isArray(roles_show_array) && Array.isArray(req_roles)) {
        custom_rules.forEach((crule) => {
            if (crule.rule_type === "linked") {
                if (crule.object_property === property_name) {
                    if (crule.object_value.includes(property_value)) {
                         // add roleids to roles_show_array if not there
                        if (Array.isArray(crule.linked_req_properties)) {
                            crule.linked_req_properties.forEach((linkedprop) => {
                                if (linkedprop.includes("Role")) {
                                    const roleid = linkedprop.replace("Role:", "");
                                    if (!roles_show_array.includes(roleid)) {
                                        roles_show_array.push(roleid)
                                    }
                                }
                            })
                        }

                    } else {
                        // remove roleids from roles_show_array if there
                        // and not part of default req_roles
                        if (Array.isArray(crule.linked_req_properties)) {
                            crule.linked_req_properties.forEach((linkedprop) => {
                                if (linkedprop.includes("Role")) {
                                    const roleid = linkedprop.replace("Role:", "");
                                    if (roles_show_array.includes(roleid) && !req_roles.includes(roleid)) {
                                        // remove roelid value from current_required array
                                        const index = roles_show_array.indexOf(roleid);
                                        if (index > -1) {
                                            roles_show_array.splice(index, 1);
                                        }
                                    }
                                }
                            })
                        }
                    }
                }
            }
        })
    }
    return roles_show_array;
}

// filters a list of custom rules and return only the ones matching a property
export function FilterCustomRules(custom_rules, property_name) {
    let result=[];
    custom_rules.forEach((crule) => {
        if (crule.object_property === property_name) {
            result.push(crule);
        }
    })
    return result;
}

export function ValidatePropertyDisplayCondition(property_name,conditional_props,filmstateobj) {
    // by default we display everything
    let result = true;
    conditional_props.forEach((crule) => {
        if (crule.property_name === property_name) {
            if (filmstateobj.hasOwnProperty(crule.linked_name)) {
               if (filmstateobj[crule.linked_name]){
                   // if a rule if found then it is enforced
                   // item is displayed only if rule is valid
                   if (crule.linked_values.includes(filmstateobj[crule.linked_name]) || crule.linked_values==="*") {
                        result = true;
                    } else {
                        result = false;
                    }
               }else{
                   // if value of linked property is empty the rule is not a match                  
                   result = false;
               }
                
            }else{
                // fallback if film does not have the property set 
                result = false;
            }
        }
    })
    return result;
}

export function ValidatePropertyRequiredCondition(property_name, conditional_props, filmstateobj) {
    let result = true;
    conditional_props.forEach((crule) => {
        if (crule.property_name === property_name) {
            if (filmstateobj.hasOwnProperty(crule.linked_name)) {
                if (filmstateobj[crule.linked_name]) {
                    if (crule.linked_values.includes(filmstateobj[crule.linked_name])) {
                        if (crule.required) {
                            result = true;
                        } else {
                            result = false;
                        }
                    } else {
                        result = false;
                    }

                } else {
                    result = false;
                }                
            }else{
                result = false;
            }
        }
    })
    return result;
}

export function ValidateRoleCondition(role_id,conditional_roles,filmstateobj) {
    // by default the role is displayed
    let result = true;
    conditional_roles.forEach((crule) => {
        if (crule.role_id === role_id) {
            if (filmstateobj.hasOwnProperty(crule.linked_name)) {
                // if a matching rule is found then it is enforced
                // item is displayed only if rule is valid
                if (filmstateobj[crule.linked_name]) {
                    if (crule.linked_values.includes(filmstateobj[crule.linked_name])) {
                        result = true;
                    } else {
                        result = false;
                    }
                } else {
                    result = false;
                }                
            }else{
                // if current film does not have that property set, then hide the role
                // condition rule is not met
                result = false;
            }
        }
    })
    return result;
}

export function ValidateRoleReqCondition(role_id,conditional_props,filmstateobj) {
    let result = true;
    conditional_props.forEach((crule) => {
        if (crule.role_id === role_id) {
            if (filmstateobj.hasOwnProperty(crule.linked_name)) {
                if (filmstateobj[crule.linked_name]) {
                    if (crule.linked_values.includes(filmstateobj[crule.linked_name])) {
                        result = true;
                    } else {
                        result = false;
                    }
                }
                else {
                    result = false;
                }              
            }else{
                // fallback to false if filmobjet does not have the property
                // but a rule exists for role_id
                result=false;
            }
        }
    })
    return result;
}

export function IsConditionalProp(property_name, conditional_props){
    let result = false;
    if (conditional_props.filter(function(e) { return e.property_name === property_name; }).length > 0) {
        /* list contains the element we're looking for */
        result=true;
      }
    return result;
}

export function IsConditionalRole(role_id, conditional_roles){
    let result = false;
    if (conditional_roles.filter(function(e) { return e.role_id === role_id; }).length > 0) {
        /* list contains the element we're looking for */
        result=true;
      }
    return result;
}

export function RemoveRoleRecord(role_list, roleid, mainid, secid) {
    // locate record matching roleid, mainid and secid in role_list
    // if found remove from array
    if (Array.isArray(role_list)) {
        role_list.forEach((roleitem) => {
            if (roleitem.roleId === roleid.toString()) {
                if (secid > 0) {
                    // we need to make sure we have both entities in the roleitem object
                    // to make sure we are removing the right object
                    if (roleitem.otherEntity && roleitem.mainEntity) {
                        if (roleitem.otherEntity.entityObjId === secid && roleitem.mainEntity.entityObjId === mainid) {
                            const index = role_list.indexOf(roleitem);
                            if (index > -1) {
                                role_list.splice(index, 1);
                            }
                        }
                    }
                } else {
                    if (roleitem.mainEntity && !roleitem.otherEntity) {
                        // this means we have a role with no sec entity
                        if (roleitem.mainEntity.entityObjId === mainid) {
                            const index = role_list.indexOf(roleitem);
                            if (index > -1) {
                                role_list.splice(index, 1);
                            }
                        }
                    }
                }
            }
        });
    }
    return role_list
}

export function ParseEntityOptions(option_list) {
    const result = [];
    if (Array.isArray(option_list)) {
        option_list.forEach((option) => {
            if (option.includes(":")) {
                const optionarray = option.split(":");
                const optionitem = { "name": optionarray[0], "value": parseInt(optionarray[1]) };
                result.push(optionitem);
            } else {
                if (option.includes("Select")) {
                    const optionitem = { "name": option, "value": 0 };
                    result.push(optionitem);
                }
            }
        });
    }
    return result;
}

export function GetEntityFormHeader(formData) {
    let result = "";
    // these are the roles where entities have not sec contact
    let singleentityroles = [11, 26, 10, 19, 22, 23, 21, 25, 20, 24];
    if (formData.form_header) {
        result = formData.form_header;
        if (formData.entity_type) {
            // special use case for director / liason
            if (formData.entity_type === "person") {
                if (formData.role_id === 17) {
                    result = formData.form_header
                } else {
                    if (!singleentityroles.includes(formData.role_id)) {
                        result = result + " (Contact)";
                    }                    
                }
            }
        }
    }
    return result;
}

export function GetCurrentRoleGridData(role_list) {
    const result = [];
    if (Array.isArray(role_list)) {
        role_list.forEach((roleitem) => {
            if (roleitem.otherEntity) {
                // we need to make sure we have both entities in the roleitem object
                // to parse both
                if (roleitem.otherEntity && roleitem.mainEntity) {
                    let mainname = "";
                    let secname = ""
                    const mainid = roleitem.mainEntity.entityObjId.toString();
                    const secid = roleitem.otherEntity.entityObjId.toString();
                    const itemkey = mainid + "," + secid;
                    if (roleitem.mainEntity.isPerson) {
                        mainname = roleitem.mainEntity.firstName + " " + roleitem.mainEntity.lastName;
                    } else {
                        mainname = roleitem.mainEntity.companyName;
                    }
                    if (roleitem.otherEntity.isPerson) {
                        secname = roleitem.otherEntity.firstName + " " + roleitem.otherEntity.lastName;
                    } else {
                        secname = roleitem.otherEntity.companyName;
                    }
                    const roleentry = { "key": itemkey, "mainid": mainid, "secid": secid, "mainname": mainname, "secname": secname };
                    result.push(roleentry);
                }
            } else {
                if (roleitem.mainEntity && !roleitem.otherEntity) {
                    // this means we have a role with no sec entity
                    let mainname = ""; 
                    let secname = ""
                    const mainid = roleitem.mainEntity.entityObjId.toString();
                    const secid = "";
                    if (roleitem.mainEntity.isPerson) {
                        mainname = roleitem.mainEntity.firstName + " " + roleitem.mainEntity.lastName;
                    } else {
                        mainname = roleitem.mainEntity.companyName;
                    }
                    const roleentry = { "key": mainid, "mainid": mainid, "secid": secid, "mainname": mainname, "secname": secname };
                    result.push(roleentry);
                }
            }

        });
    }
    return result;
}

export function UpdateRolePrimaries(rolePrimaries, primary, removeAction) {
    if (removeAction) {
        if (Array.isArray(rolePrimaries)) {
            rolePrimaries.forEach((primitem) => {
                if (primitem.roleIds === primary.roleIds) {                    
                    const index = rolePrimaries.indexOf(primitem);
                    if (index > -1) {
                        rolePrimaries.splice(index, 1);
                    }
                }
            })
        }
    }
    else {
        // locate current record (if present)
        // remove and add new one to array
        if (Array.isArray(rolePrimaries)) {
            rolePrimaries.forEach((primitem) => {
                if (primitem.roleIds === primary.roleIds) {
                    const index = rolePrimaries.indexOf(primitem);
                    if (index > -1) {
                        rolePrimaries.splice(index, 1);
                    }
                }
            });
            rolePrimaries.push(primary)
        }
    }
    return rolePrimaries;
}

export function GetRemoveRoleDetails(role_list, remkey) {
    let mainid = 0;
    let secid = 0;
    let keyarray = remkey.split(",");
    mainid = parseInt(keyarray[0]);
    secid = parseInt(keyarray[1]);
    let result = "";
    let index = -1;
    if (Array.isArray(role_list)) {
        role_list.forEach((roleitem) => {            
            if (secid > 0) {
                // we need to make sure we have both entities in the roleitem object
                // to make sure we are removing the right object
                if (roleitem.otherEntity && roleitem.mainEntity) {
                    if (roleitem.otherEntity.entityObjId === secid && roleitem.mainEntity.entityObjId === mainid) {
                        index = role_list.indexOf(roleitem);
                    }
                }
            } else {
                if (roleitem.mainEntity && !roleitem.otherEntity) {
                    // this means we have a role with no sec entity
                    if (roleitem.mainEntity.entityObjId === mainid) {
                        index = role_list.indexOf(roleitem);
                    }
                }
            }
            
        });
    }
    if (index > -1) {
        const delrole = role_list[index];
        if (delrole.mainEntity.isPerson) {
            result = delrole.mainEntity.firstName + " " + delrole.mainEntity.lastName;
        } else {
            result = delrole.mainEntity.companyName
        }
        if (delrole.otherEntity) {
            if (delrole.otherEntity.isPerson) {
                result = result + " (" + delrole.otherEntity.firstName + " " + delrole.otherEntity.lastName + ")";
            } else {
                result = result + " (" + delrole.otherEntity.companyName +")"
            }
        }
    }
    return result;
}

export function ReplacePlaceHolders(copytext, values) {
    for (const [key, value] of Object.entries(values)) {
        copytext = copytext.replace("{" + { key } + "}", { value })
    }
    return copytext;
}

export function SaveTermsRead(filmid,ttlminutes) {
    const now = new Date();
    // note: 2592000000 is the number of milliseconds in 30 days
    const ttl = ttlminutes * 60000;
    const expiry= now.getTime() + ttl
    localStorage.setItem('tiff-patermsread-'+filmid.toString(), expiry);
}

export function GetTermsRead(filmid) {
    let result = false;
    const tempread = localStorage.getItem('tiff-patermsread-' + filmid.toString());
    if (!tempread) {
        return false;
    }
    const now = new Date();
    if (now.getTime() < tempread) {
        result = true;
    } else {
        result = false;
        // delete entry since it is now expired
        localStorage.removeItem('tiff-patermsread-' + filmid.toString())
    }
    return result;
}

export function OptionsContainsValue(options, value) {
    let result = false;
    // todo, check if list of options include value as value
    // options could be array of single strings
    // or array of "caption:value" strings
    if (options.filter(function (e) { return e.endsWith(value.toString()); }).length > 0) {
        /* list contains the value */
        result = true;
    }
    return result;
}

export function ParseHelperCopy(helpercopy) {
    let result = {};
    let precopy = "";
    let postcopy = "";
    const indexpost = helpercopy.indexOf("@post:");
    const indexpre = helpercopy.indexOf("@pre:");   
    if (indexpost < 0 && indexpre < 0) {
        // regular use case, helper should be post
        postcopy = helpercopy;
    }
    if (indexpre > -1) {        
        // check if if we have also a post
        if (indexpost > -1) {
            // post copy should be the text between @post and the end of the text
            postcopy = helpercopy.slice(indexpost).replace("@post:", "");
            precopy = helpercopy.slice(0, indexpost).replace("@pre:", "");
        } else {
            precopy = helpercopy.replace("@pre:", "");
        }
    } 
    result["pre"] = precopy;
    result["post"] = postcopy;
    return result
}

export function UpdateRoleItemContact(rolelist, roleid, mainid, newroleitem) {
    rolelist.forEach((roleentry) => {
        if (roleentry.roleId === roleid && roleentry.mainEntity.entityObjId === mainid) {
            roleentry = newroleitem;
        }
    })
    return rolelist
}

export function ResetHiddenConditionalProperties(filmstateobj, conditional_props) {
    conditional_props.forEach((crule) => {
        if (filmstateobj.hasOwnProperty(crule.property_name)) {
            if (filmstateobj.hasOwnProperty(crule.linked_name)) {
                if (filmstateobj[crule.linked_name]) {
                    if (!(crule.linked_values.includes(filmstateobj[crule.linked_name]) || crule.linked_values === "*")) {
                        // condition is not met, linked property is now hidden so value must be reset
                        if (filmstateobj[crule.property_name]) {
                            filmstateobj[crule.property_name] = "";
                        }
                    }
                }
                else {
                    // if linked property is null but rule is any value do reset 
                    if (crule.linked_values === "*") {
                        if (filmstateobj[crule.property_name]) {
                            filmstateobj[crule.property_name] = "";
                        }
                    }
                }
            }            
        } 
    })
    return filmstateobj
}


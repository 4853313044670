const baseurl = "https://publicityassignmentadmin.tiff.net/";
//const baseurl = "https://invitationsadmin.tiff.net/";
// const baseurl = "https://tiffinvitationsadmin-staging.azurewebsites.net";
//const baseurl = "https://localhost:44397";

export async function loginUser(username, password) {
    return fetch(baseurl+'/api/film/login', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'username': username,
            'password': password
        }
    })
        .then(data => data.json())
}

export async function GetCompletionStatus(credentials, filmid,formid,sectionid) {
    return fetch(baseurl+'/api/film/review/'+filmid.toString()+'?formid='+formid.toString()+'&sectionid='+sectionid.toString(), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-credentials': credentials
        }
    })
        .then(data => data.json())
}

export async function GetFilm(credentials, filmid) {
    return fetch(baseurl+'/api/film/'+filmid.toString(), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-credentials': credentials
        }
    })
        .then(data => data.json())
}

export async function GetEntity(credentials, entityid, filmid) {
    return fetch(baseurl+'/api/entity/'+entityid.toString()+'?filmid='+filmid.toString(), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-credentials': credentials
        }
    })
        .then(data => data.json())
}

export async function GetRoleForm(credentials, formid, roleid, filmid) {
    const targeturl = baseurl + '/api/roleform/' + roleid.toString()+'?formid='+formid.toString()+'&filmid='+filmid.toString();
    return fetch(targeturl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-credentials': credentials
        }
    })
        .then(data => data.json())
}

export async function GetEntityForm(credentials, roleid, filmid, entityid, sec) {
    let mainsec = "";
    if (sec) {
        mainsec = "&mainsec=sec";
    }
    const targeturl = baseurl + '/api/entityform/' + roleid.toString()+'?filmid='+filmid.toString()+'&entityid='+entityid.toString()+mainsec;
    return fetch(targeturl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-credentials': credentials
        }
    })
        .then(data => data.json())
}

export async function SaveFilm(credentials, filmid, jsonobj) {
    return fetch(baseurl+'/api/film/'+filmid.toString(), {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-credentials': credentials
        },
        body: JSON.stringify(jsonobj)
    })
        .then(data => data.json())
}

export async function SubmitFinalFilm(credentials, filmid) {
    return fetch(baseurl+'/api/film/submitfinal/'+filmid.toString(), {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-credentials': credentials
        }
    })
        .then(data => data.json())
}

export async function SaveRole(credentials, roleobj) {
    return fetch(baseurl+'/api/role', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-credentials': credentials
        },
        body: JSON.stringify(roleobj)
    })
        .then(data => data.json())
}

export async function RemoveRole(credentials, roleobj) {
    return fetch(baseurl + '/api/role', {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-credentials': credentials
        },
        body: JSON.stringify(roleobj)
    })
        .then(data => data.json())
}

export async function SaveEntity(credentials, filmid, entityobj) {
    return fetch(baseurl+'/api/entity?filmid='+filmid.toString(), {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-credentials': credentials
        },
        body: JSON.stringify(entityobj)
    })
        .then(data => data.json())
}
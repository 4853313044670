import React from 'react';
import './FormElements.css';
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import { ParseHelperCopy } from '../Helpers/Helpers';

export default function CheckBox(props) {
    
    // logic for handling helper copy before or after main control    
    let helpercopy_pre = "";
    let helpercopy_post = "";
    if (props.helper) {
        const helperobj = ParseHelperCopy(props.helper);
        if (helperobj.pre.length > 0) {
            helpercopy_pre = <span dangerouslySetInnerHTML={{ __html: helperobj.pre }} ></span>;
        }
        if (helperobj.post.length > 0) {
            helpercopy_post = <span dangerouslySetInnerHTML={{ __html: helperobj.post }} ></span>;
        }
    }
    // end of helper copy handling

    // handle required label
    let labelreq = "";
    if (props.req) {
        labelreq = " *";
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <Box sx={{ my: 1 }}>
                    <label htmlFor={props.name}>{props.label} </label>
                    <Box component="span" sx={{ color: 'red' }}>
                        {labelreq}
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={8}>
                <Box
                    sx={{
                        width: 800,
                        maxWidth: '100%',
                        margin: 1,
                        alignItems: 'left'
                    }}
                >   {helpercopy_pre}
                    <input
                        id={props.name}
                        onChange={props.onChange}
                        name={props.name}
                        type="checkbox"
                        value={props.value}
                        checked={props.checked}
                    />{props.options}
                    <FormHelperText sx={{ marginLeft: 0 }}>{helpercopy_post}</FormHelperText>
                </Box>
            </Grid>
        </Grid >
    );
}
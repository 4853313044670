import React from 'react';
import './Logout.css';
import Login from '../Login/Login';
import useSession from '../../useSession';
import LogoutIcon from '@mui/icons-material/Logout';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export default function Logout() {    

    const { setSession } = useSession();

    const handleSubmit = async e => {
        sessionStorage.removeItem("tiff_film");        
        return <Login setSession={setSession}  />
    }

    return (<Box>
              <Button variant="outlined" href="/" onClick={handleSubmit} size="small" type="submit" value="Logout" startIcon={<LogoutIcon />}>Logout</Button>
           </Box>      
    )
}

import React, { useState } from 'react';
import './FormElements.css';
import Grid from "@mui/material/Grid";
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { ParseHelperCopy } from '../Helpers/Helpers';
import './FormElements.css';

export default function MultiCheckOther(props) {  

    // custom props for handling details box caption
    // and the property selection that triggers displaying the optional box
    let detail_props = "";
    let detail_caption = "";   
    if (props.custom_props) {
        if (props.custom_props.detail_props) {
            detail_props = props.custom_props.detail_props;
        }
        if (props.custom_props.detail_caption) {
            detail_caption = props.custom_props.detail_caption;
        }       
    }

    const [mvalue, setMvalue] = useState(props.value); 

    let stateobj = {};        

    let seloptions = [];
    let poptions = props.options;
    // check if options passed have values and text items
    if (poptions) {
        props.options.forEach((item) => {
            let itemvalue = item;
            let optiontext = item
            let optionvalue = item
            if (itemvalue.includes(":")) {
                // split value and text if value is formatted as "text:value"
                const myArray = itemvalue.split(":");
                optiontext = myArray[0];
                optionvalue = myArray[1];
            }
            // use case for options that trigger details show
            if (detail_props.includes(optionvalue)) {
                optiontext += " (" +  detail_caption + ")"
            }
            const lvalue = {};
            lvalue.value = optionvalue;
            lvalue.text = optiontext;
            seloptions.push(lvalue);
            stateobj[optionvalue] = isChecked(optionvalue);
        })
    }

    let othervalue = ""; 
    const mvaluearray = mvalue.split(",");
    mvaluearray.forEach((vitem) => {
        if (!vitem.includes("Details")) {
            if (isChecked(vitem)) {
                stateobj[vitem] = true;
            } else {
                stateobj[vitem] = false;
            }
        } else {   
            othervalue = vitem.replace("Details: ", "");
            /*stateobj["Details"] = othervalue;*/
        }
    });


    function isChecked(optionname) {
        let result = false;
        if (mvalue.includes(optionname)) {
            result = true;
        }
        return result
    }  

    function handleChangeCheck(evt) {
        const target = evt.target.name.replace(props.name + "_", "");
        let newvalue = mvalue;
        if (evt.target.checked) {
            if (mvalue === "") {
                setMvalue(target);
                newvalue = target;
            } else {
                setMvalue(mvalue + "," + target)
                newvalue = mvalue + "," + target;
            }
        }
        else {
            if (mvalue.includes(target)) {
                newvalue = mvalue.replace(target, "");
                newvalue = newvalue.replace(",,", ",");
                setMvalue(newvalue);
            }
        }
        props.onChange(props.name, newvalue);
    }   

    function handleChangeDetails(evt) {
        let isupdate = false;  
        let detailindex = -1;
        mvaluearray.forEach((vitem) => {
            if (vitem.includes("Details")) {               
                isupdate = true;
                detailindex = mvaluearray.indexOf(vitem);
            } 
        });
        if (!isupdate) {
            // create a detail item in the array
            mvaluearray.push("Details: " + evt.target.value);
        } else {   
            // remove old deatil item and re-create with updated value
            if (detailindex > -1) {
                mvaluearray.splice(detailindex, 1);
                mvaluearray.push("Details: " + evt.target.value);
            }
        }
        setMvalue(mvaluearray.toString());
        props.onChange(props.name, mvaluearray.toString());
    }
    

    // handle required label
    let labelreq = "";
    if (props.req) {
        labelreq = " *";
    }

    // handle label name
    const labelname = props.name + "label";

    const texfieldid = props.name + "otherbox"; 

    function resetDetailsText() {
        let detailindex = -1;
        mvaluearray.forEach((vitem) => {
            if (vitem.includes("Details")) {                
                detailindex = mvaluearray.indexOf(vitem);
            }
        });
        if (detailindex > -1) {
            mvaluearray.splice(detailindex, 1);  
            setMvalue(mvaluearray.toString());
        }
    }

    // this requires a prop called opendetails
    // it should be an array of properties that 
    // when selected should open the extra details textbox
    function displayDetails() {
        let result = false;
        if (detail_props) {
            if (detail_props.includes(",")) {
                const detailarray = detail_props.split(",");
                detailarray.forEach((item) => {
                    if (props.value.includes(item)) {
                        result = true;
                    }
                });
            } else {
                if (props.value.includes(detail_props)) {
                    result = true;
                }
            }            
        }
        return result;
    }
    const [showother, setShowOther] = useState(false);
    // handle hide/show textbox for other details
    if (displayDetails()) {
        if (!showother) {
            setShowOther(true);
        }
    }
    else {
        if (showother) {
            setShowOther(false);            
        }  
        resetDetailsText();
    } 

    // logic for handling helper copy before or after main control    
    let helpercopy_pre = "";
    let helpercopy_post = "";
    if (props.helper) {
        const helperobj = ParseHelperCopy(props.helper);
        if (helperobj.pre.length > 0) {
            helpercopy_pre = <span dangerouslySetInnerHTML={{ __html: helperobj.pre }} ></span>;
        }
        if (helperobj.post.length > 0) {
            helpercopy_post = <span dangerouslySetInnerHTML={{ __html: helperobj.post }} ></span>;
        }
    }
    // end of helper copy handling

    return (props.show &&
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <Box sx={{ my: 1 }}>
                    <label htmlFor={props.name}>{props.label} </label>
                    <Box component="span" sx={{ color: 'red' }}>
                    {labelreq}
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={8}>
                <div>
                    <FormControl sx={{ m: 1, minWidth: 120 }} component="fieldset" variant="standard">
                        <FormLabel component="legend" id={labelname}></FormLabel>
                       {helpercopy_pre}
                        <FormGroup>
                            {seloptions.map((soption) => (
                                <FormControlLabel key={soption.value} 
                                    control={
                                        <Checkbox checked={stateobj[soption.value]} onChange={handleChangeCheck} name={props.name + "_" + soption.value} />
                                    }
                                    label={soption.text}
                                />
                            ))}
                            <input type="hidden" id={props.name} name={props.name} value={mvalue}></input>
                        </FormGroup>                       
                        <div>
                            {showother ? <TextField fullWidth label={detail_caption} id={texfieldid} name={texfieldid} onChange={handleChangeDetails} value={othervalue} /> : null}
                        </div>                     
                        <FormHelperText sx={{ marginLeft: 0 }}>{helpercopy_post}</FormHelperText>
                    </FormControl>
                </div>
            </Grid>
        </Grid >
    );
}
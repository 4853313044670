import React from 'react';
import './FormElements.css';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function ParseQs(qsname) {
    let params = (new URL(document.location)).searchParams;
    let qsvalue = "";
    if (params.has(qsname)) {
        qsvalue = params.get(qsname);
    }
    return qsvalue;
} 

export default function RoleTable(props) {   

    let remode = false;
    const remkey = ParseQs("remkey");
    if (remkey.length > 2) {
        remode = true;
    }
       

    // handles the radio checked action and passes the values upstream
    function handleChangeCheck(evt) {
        const rowkey = evt.target.name.replace("radio_", "");        
        let isPrimary = evt.target.checked;
        let primary_group = "";
        if (props.custom_props) {
            if (props.custom_props.primary_group) {
                // this is a role that requires primary selection
                // the primaries key is a the primary_group
                primary_group = props.custom_props.primary_group;
            }
        }
        let prim_mainid = -1;
        let prim_secid = -1;
        if (rowkey.includes("-")) {
            const keyarray = rowkey.split("-");
            if (keyarray.length === 2) {
                prim_mainid = parseInt(keyarray[0]);
                prim_secid = parseInt(keyarray[1]);
            }
        }
        if (prim_mainid > -1 && prim_secid > -1) {
            props.handlePrimary(primary_group, prim_mainid, prim_secid, isPrimary);
        }
    }

    // if content element has a primary_group custom_prop 
    // it means this role must enable the primary contact radio button here
    let displayprimarycol = false;
    let displaycontactupdatelink = false;
    if (props.custom_props) {
        if (props.custom_props.primary_group) {
            // this is a role that requires primary selection
            displayprimarycol = true;
        }
        if (props.custom_props.contact_update_opt) {
            // this is a role that where contact can be edited without remove
            displaycontactupdatelink = true;
        }
    }

    // update row data with film rolePrimaries data
    // so we can flag the primaries (if saved at the film level)
    // this data is refreshed in parent component 
    // so we need to do this before rendering
    const rowdata = props.roledata;
    const rolePrimaries = props.rolePrimaries;
    let prim_mainid = -1;
    let prim_secid = -1;
    if (Array.isArray(rolePrimaries)) {
        if (rolePrimaries.length > 0) {
            rolePrimaries.forEach((primrole) => {
                if (primrole.roleIds.includes(props.roleid)) {
                    prim_mainid = primrole.mainId;
                    prim_secid = primrole.secId;
                }
            });
        }
    }
    if (Array.isArray(rowdata)) {
        if (rowdata.length > 0 && prim_mainid > -1 && prim_secid >-1 ) {
            rowdata.forEach((rowitem) => {
                if (rowitem.mainid === prim_mainid && rowitem.secid === prim_secid) {
                    rowitem.primary = true;
                }
                else {
                    rowitem.primary = false;
                }
            });
        }
    }

    let contactcaption = "contact";
    if (props.roleid === "17"){
        contactcaption = "liaison";
    }
        
    // baseurls for entity edit 
    const entityEditMain = "/entity?roleid=" + props.roleid + "&filmid=" + props.filmid + "&sectionid=" + props.sectionid + "&entityid=";
    const entityEditSec = "/entity?roleid=" + props.roleid + "&filmid=" + props.filmid + "&sectionid=" + props.sectionid + "&mainsec=sec&entityid=";

    const itemDelete = "/role?id=" + props.roleid + "&sectionid=" + props.sectionid + "&remkey=";

    const addcontactlink = "/addcontact?id=" + props.roleid + "&sectionid=" + props.sectionid + "&filmid=" + props.filmid + "&mainid=";
    // turn off liaiason edit link for now while we build the screen
    //displaycontactupdatelink = false;

    return (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 800 }} aria-label="simple table">
                        <TableBody>
                            {rowdata.map((row) => (
                                <TableRow
                                    key={row.key}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >

                                    <TableCell component="th" scope="row">
                                        {row.mainname}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button variant="contained" href={entityEditMain + row.mainid} startIcon={<EditIcon />}>
                                            Edit
                                        </Button>
                                    </TableCell>
                                    <TableCell align="right" >
                                        {row.secname}
                                    </TableCell>
                                    {row.secname !== "" &&
                                        <TableCell align="right">
                                            <Button variant="contained" href={entityEditSec + row.secid} startIcon={<EditIcon />}>
                                                Edit
                                            </Button>
                                        </TableCell>
                                    }
                                    {row.secname === "" &&
                                        <TableCell align="right">
                                            {displaycontactupdatelink &&
                                                <Button variant="contained" href={addcontactlink + row.mainid} startIcon={<EditIcon />}>
                                                    Add {contactcaption}
                                                </Button>
                                            }
                                        </TableCell>
                                    }
                                    {displayprimarycol &&
                                        <TableCell align="right">
                                            <input
                                                type="radio"
                                                name={"radio_" + row.key}                                                
                                                checked={row.primary}
                                                onChange={handleChangeCheck}
                                            /> Primary Contact
                                        </TableCell>
                                    }
                                    <TableCell align="right">
                                        {!remode &&
                                            <Button variant="contained" name={row.key} href={itemDelete + row.mainid + "," + row.secid} startIcon={<DeleteIcon />}>
                                                Remove
                                            </Button>
                                        }
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>          
    );
}
import React, { useState, useEffect } from 'react';
import useSession from '../../useSession';
//import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import { GetFormEntityObject, GetEntityFormHeader} from '../Helpers/Helpers'
import FormContentList from "../FormElements/FormContentList";
import { GetEntityForm, SaveEntity, GetFilm } from '../ApiClient/ApiClient';
import MuiAlert from '@mui/material/Alert';
import MainNav from '../MainNav/MainNav';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ParseQs(qsname) {
    let params = (new URL(document.location)).searchParams;
    let qsvalue = "";
    if (params.has(qsname)) {
        qsvalue = params.get(qsname);
    }
    return qsvalue;
} 

export default function Entity() {  

    const { sessionItem, setSession } = useSession();   
    const credentials = sessionItem.accesstoken;  
    const [formData, setFormData] = useState();
    const [sbopen, setSbOpen] = useState(false);
    const [opMessage, setOpMessage] = useState("");    
    const filmid = sessionItem.film.filmObjId;    

    if (sessionItem.film.submittedfinal) {
        // if film is submitted final
        // redirect to dashboard page
        // this page should not be displayed
        window.location.href = '/';
    }

    // save stateformobj in the values useState for all elements
    const [values, setValues] = useState();

    const [callcount, setCallCount] = useState(0);

    let entityid = 0;
    const entitystring = ParseQs("entityid");
    if (entitystring) {
        entityid = parseInt(entitystring);
    }
    if (entityid === 0) {
        // special case for new entities
        // look into formData.entity_id
        // to verify if save operation was triggered
        // if so get the entity id from there
        // to amke sure that calls to API retrieve this entity
        // any time the page is refreshed
        if (formData) {
            if (formData.entity_id) {
                if (formData.entity_id > 0) {
                    entityid = formData.entity_id
                }
            }
        }
    }    

    // code below handles async call to API
    // the array [] passed as the second param makes sure this is executed only once 
    // since those values should not change during the screen actions.
    useEffect(() => {
        let roleid = 0;
        const rolestring = ParseQs("roleid");
        if (rolestring) {
            roleid = parseInt(rolestring);
        }        
        const mainsec = ParseQs("mainsec");  
        let sec = false;
        if (mainsec === "sec") {
            sec = true;
        }
        const credentials = sessionItem.accesstoken;
        const filmid = sessionItem.film.filmObjId;        
        const getFormData = async () => {
            // set variable to make sure we don't run this again when the page is refreshed
            //setApiCalled(true);
            const respo = await GetEntityForm(credentials, roleid, filmid, entityid, sec);
            if (respo.role_name) {
                setFormData(respo);                 
            } else {
                // handle error             
                setOpMessage("Unexpected error while loading the data for this page.")
                setSbOpen(true);
            }
        };
        getFormData();
    }, [sessionItem.accesstoken, sessionItem.film.filmObjId, callcount, entityid]);
    // end of async call logic

    //let navigate = useNavigate();    
    
    // not used for entities but declared becuase it is required by FormContentList
    const presentation_formats = [];    
    const conditional_props = [];
    const conditional_roles = [];
    const requiredrolesarray = [];
    const staterolesar = [];

    // these will come from API Call to entityform end point
    let myentity = {};
    let content_elements = [];
    let requiredarray = [];  
    //let requiredarrayalt = [];
    let formheader = "";
    let formmessage = ""; 

    // populate options from API call   
    if (formData) {
        if (formData.content_elements) {
            content_elements = formData.content_elements.slice();
            // hack for company email caption
            // I don't like it but it works for now
            content_elements.forEach((ceitem) => {
                if (ceitem.content_copy.startsWith("Email")) {
                    if (formData.entity_type === "company") {
                        ceitem.content_copy = ceitem.content_copy.replace("Email", "Company Email");
                    }
                }
            })
        }
        if (formData.entity) {
            myentity = formData.entity;
            if (!values) {
                const tempentityobj = GetFormEntityObject(content_elements, myentity);
                setValues(tempentityobj);
            }            
        }
        if (formData.req_fields) {
            requiredarray = formData.req_fields.slice();
        }
        //if (formData.req_props_alternate) {
        //    requiredarrayalt = formData.req_props_alternate.slice();
        //}
        // get form headers and messages
        if (formData.form_header) {
            formheader = GetEntityFormHeader(formData);            
        }
        if (formData.message) {
            formmessage = <Alert severity="warning">{formData.message}</Alert>;
        }
       
    }    
    
    // define the handler for the onchange of each form element
    function handleChange(evt) {
        const value = evt.target.value;
        setValues({
            ...values,
            [evt.target.name]: value
        });         
    }

    function handleChangeTyped(targetname,targetvalue) {
        setValues({
            ...values,
            [targetname]: targetvalue
        });
    }

    // handler for checkboxes
    function handleChangeCheck(evt) {
        const value = evt.target.checked;
        setValues({
            ...values,
            [evt.target.name]: value
        });
    }

    //handler for multigrid (multiselect DD)
    function handleChangeMulti(evt) {
        // value comes as an array
        const arrayvalue = evt.target.value;
        // flatten as a csv string for saving in useState object
        const csvstring = arrayvalue.join(",");
        setValues({
            ...values,
            [evt.target.name]: csvstring
        });
    }

    // handler 1 for dropdown plus other textbox
    function handleChangeDDOSel(evt) {
        const value = evt.target.value;
        setValues({
            ...values,
            [evt.target.name]: value
        });
    }

    // hander 2 for dropdown plus other textbox
    function handleChangeDDOText(evt) {
        const value = "Other: " + evt.target.value;
        const targetname = evt.target.name.replace("otherbox", "");
        setValues({
            ...values,
            [targetname]: value
        });
    } 

    // logic for calculating the navigate back URL
    const sectionid = ParseQs("sectionid");
    const roleid = ParseQs("roleid");
    let navbackurl = "/role?id=" + roleid + "&sectionid=" + sectionid;
    //  determine if we navigate back to addcontact
    const backref = ParseQs("backref");
    if (backref === "ac") {
        navbackurl = "/addcontact?id=" + roleid + "&sectionid=" + sectionid;
    }
    // preset main and sec values if passed in qs
    const mainsec = ParseQs("mainsec");
    let sec = false;
    if (mainsec === "sec") {
        sec = true;
    }    
    // determine if this entity was inserted as new    
    let isinserted = false;
    if (formData) {
        if (entitystring === "" && formData.entity_id > 0) {
            isinserted = true;
        }
    }    
    if (isinserted) {
        // this entity is new navback with this new id pre-selected
        if (sec) {
            navbackurl += "&secid=" + formData.entity_id.toString();
        } else {
            navbackurl += "&mainid=" + formData.entity_id.toString();
        }
    } 
    // navigate back with the previously selected values
    const mainidqs = ParseQs("mainid");
    if (mainidqs !== "0" && mainidqs !== "" && !navbackurl.includes("&mainid=")) {
        navbackurl += "&mainid=" + mainidqs;
    }
    const secidqs = ParseQs("secid");
    if (secidqs !== "0" && secidqs !== "" && !navbackurl.includes("&secid=")) {
        navbackurl += "&secid=" + secidqs;
    }
    // end of navigate back URL logic

    //function RedirectBack() {        
    //    //navigate(navbackurl);
    //}
    
    function handleSnackBarClose() {
        if (!opMessage.includes("Operation Error")) {            
            // reset severity for next time
           
        }
        setOpMessage("");
        setSbOpen(false);
    }

    const sbaction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackBarClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );    
    
    // parse form object and generate input object list 
    // save each input in formElements
    // each input must have a useState obj for setting the value

    const handleSubmit = async e => {
        // refresh entity obj
        // do stuff, save to API

        e.preventDefault();       
                 
        // loop values properties an assign to base entity
        for (const [key, value] of Object.entries(values)) {
            myentity[key] = value;
        }

        // if new entity we must set the isPerson property
        let entityid = 0;
        const entitystring = ParseQs("entityid");
        if (entitystring) {
            entityid = parseInt(entitystring);
        }
        if (entityid === 0) {
            // this is a new entity we need to find out
            // if it is a person or company
            if (formData) {
                if (formData.entity_type === "person") {
                    myentity["isPerson"] = true;
                } else {
                    myentity["isPerson"] = false;
                }
                if (formData.entity_id) {
                    if (formData.entity_id > 0) {
                        // if entity was saved in a previous action
                        // we must use the entity id created in that step
                        // to avoid creating new entries every time we hit Save
                        myentity["entityObjId"] = formData.entity_id;
                    }
                }
            }
        }

        const apirespo = await SaveEntity(credentials, sessionItem.film.filmObjId, myentity);
        if (apirespo.IsError) {
            // handle error
            // Handle error            
            setOpMessage("Operation Error: " + apirespo.Message);
            setSbOpen(true);
        } else {
            // todo
            // we should grab the new entity id for preselecting the role dd
            // when the navigate back action is triggered
            // entity id should returned as apirespo.ObjectId
            if (formData && apirespo.ObjectId) {
                formData.entity_id = apirespo.ObjectId;
                setFormData(formData);
            }           

            // pull film again
            // we need to refresh all the credits where this entity is connected
            const myfilm = await GetFilm(credentials, sessionItem.film.filmObjId);            
            if (myfilm.engTitle) {
                // film pulled succesfully
                // reset dashboardrefresh so it reloads next time
                sessionItem.dashboardrefresh = true;
                // update the film data in session obj
                // update only the rolelist prop, to avoid overrriding temp film props
                sessionItem.film.rolesList = myfilm.rolesList;
                setSession(sessionItem);
                setOpMessage("Film data was updated.");
                setSbOpen(true);
            } else {
                // error message is in apinotes
                setOpMessage("Operation Error: entity was saved but failed refreshing the film data.");
                setSbOpen(true);
            }

            // this should trigger reloading the form from the API
            // required to do the validation on the server again after saving
            //window.location.reload(false);
            setCallCount(callcount + 1);
        }     

    }

    return ( 
        <div>
            <MainNav /> 
            <h3>
               {formheader}
            </h3>
            <form onSubmit={handleSubmit}>                  
                <FormContentList
                    conditional_props={conditional_props}
                    conditional_roles={conditional_roles}
                    content_elements={content_elements}
                    handleChange={handleChange}
                    handleChangeMulti={handleChangeMulti}
                    handleChangeDDOSel={handleChangeDDOSel}
                    handleChangeDDOText={handleChangeDDOText}
                    handleChangeCheck={handleChangeCheck}
                    handleChangeTyped={handleChangeTyped}
                    presentation_formats={presentation_formats}
                    requiredarray={requiredarray}
                    requiredrolesarray={requiredrolesarray}
                    staterolesar={staterolesar}
                    filmid={filmid}
                    values={values}
                />
            
            <Box component="span" display="flex" justifyContent="space-between">
                    <Button variant="outlined" color="secondary" name="navbackrolebt" href={navbackurl} startIcon={<ArrowBackIcon />}>
                    Back
                </Button>
                <Button variant="outlined" type="submit" value="Save" startIcon={<SaveIcon />}>Save</Button>
                </Box>
            </form>
            <Box>
                {formmessage}
            </Box>  
            <Snackbar                
                open={sbopen}
                autoHideDuration={3000}
                onClose={handleSnackBarClose}
                message={opMessage}
                action={sbaction}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            />            
        </div>
                           
        
    )
}

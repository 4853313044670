import * as React from 'react';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Logout from '../Logout/Logout';
import './MainNav.css';

function NavItem(props) {
    const location = window.location.pathname;
    let variant = "outlined"
    if (location === props.href) {
        variant = "contained";
    }
    return <Button variant={variant} size="small" name={props.name} href={props.href}>
        {props.caption}
    </Button>
}

export default function MainNav() {
    return (
        <div className="stackdiv">
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
            >
                <NavItem href="/" name="Dashboard" caption="Dashboard" />
                <NavItem href="/about" name="About" caption="About" />
                <NavItem href="/print" name="Print" caption="Printer Friendly Version" />
                </Stack>
                <Logout />
            </Box>

        </div>
    );
}
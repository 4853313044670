import React from 'react';
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import { ParseHelperCopy } from '../Helpers/Helpers';

function ParseQs(qsname) {
    let params = (new URL(document.location)).searchParams;
    let qsvalue = "";
    if (params.has(qsname)) {
        qsvalue = params.get(qsname);
    }
    return qsvalue;
} 

export default function TextInput(props) {
        
    // handle required label
    let labelreq = "";
    if (props.req) {
        labelreq = " *";
    }

    // special case for enTitle, enforce required property
    let reqflag = false;
    if (props.name === "engTitle") {
        reqflag = true;
    }

    // special case for entities
    // disable editings firstname, lastname and company name
    // of existing entities (should be enabled for new entity entries)
    let disabledflag = false;
    if (props.name === "firstName" || props.name === "lastName" || props.name === "companyName") {
        let entityid = 0;
        const entitystring = ParseQs("entityid");
        if (entitystring) {
            entityid = parseInt(entitystring);
        }
        if (entityid > 0) {
            disabledflag = true;
        }
    }

    // logic for handling helper copy before or after main control    
    let helpercopy_pre = "";
    let helpercopy_post = "";
    if (props.helper) {
        const helperobj = ParseHelperCopy(props.helper);
        if (helperobj.pre.length > 0) {
            helpercopy_pre = <span dangerouslySetInnerHTML={{ __html: helperobj.pre }} ></span>;
        }
        if (helperobj.post.length > 0) {
            helpercopy_post = <span dangerouslySetInnerHTML={{ __html: helperobj.post }} ></span>;
        }
    }
    // end of helper copy handling

    return (props.show && 
        <Grid container spacing={1}>
            <Grid item xs={4} sx={{ marginTop: 1 }} > 
                <Box sx={{ my: 1 }}>
                    <label htmlFor={props.name} >{props.label} </label>
                    <Box component="span" sx={{ color: 'red'}}>
                        {labelreq}
                    </Box>
               </Box>
            </Grid>
            <Grid item xs={8} >
                <Box
                    sx={{
                        width: 800,
                        maxWidth: '100%',
                        margin: 1,
                        alignItems: 'left'                        
                    }}
                >
                    {helpercopy_pre}
                    <TextField fullWidth
                        id={props.name}
                        name={props.name}                        
                        required={reqflag}
                        disabled={disabledflag}
                        value={props.value}
                        inputProps={{ maxLength: props.size }}
                        onChange={props.onChange}                         
                    />
                    <FormHelperText sx={{ marginLeft: 0 }}>{helpercopy_post}</FormHelperText>
                
                </Box>
            </Grid>
        </Grid>
    );
}
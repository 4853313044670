import React from 'react';
import './FormElements.css';
import Grid from "@mui/material/Grid";
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import { MenuItem } from '@mui/material';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import { ParseHelperCopy } from '../Helpers/Helpers';
import './FormElements.css';

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 6;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,            
            color: "#222222"            
        }
    }
};

function getStyles(name, optionName, theme) {
    return {
        fontWeight:
            optionName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
        
        
    };
}

const customStyles = {
    control: (base, state) => ({
        ...base,
        background: "#023950",
        // match with the menu
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "yellow" : "green",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "red" : "blue"
        }
    }),
    menu: base => ({
        ...base,
        // override border radius to match the box
        borderRadius: 0,
        // kill the gap
        marginTop: 0
    }),
    menuList: base => ({
        ...base,
        // kill the white space on first and last option
        padding: 0,
        background: "#023950"
    })
};


export default function MultiGrid(props) {
    
    const theme = useTheme(); 

    let seloptions = [];

    let poptions = props.options;       

    // multi mode requires value to be passed as an array
    let multivalues = [];

    // check if props.value is an array
    // is so copy to multivalues
    if (props.value.includes(",")){
        multivalues = props.value.split(",");
    } else {
        // single value, add to array (if any)
        if (props.value) {
            multivalues.push(props.value);
        }       
    }

    // check if options passed have values and text items
    if (poptions) {
        props.options.forEach((item) => {            
            let itemvalue = item;
            let optiontext = item
            let optionvalue = item
            if (itemvalue.includes(":")) {
                // split value and text if value is formatted as "text:value"
                const myArray = itemvalue.split(":");
                optiontext = myArray[0];
                optionvalue = myArray[1];
            } 
            const lvalue = {};
            lvalue.value = optionvalue;
            lvalue.text = optiontext;
            seloptions.push(lvalue);
        })
    }

    // handle required label
    let labelreq = "";
    if (props.req) {
        labelreq = " *";
    }

    // logic for handling helper copy before or after main control    
    let helpercopy_pre = "";
    let helpercopy_post = "";
    if (props.helper) {
        const helperobj = ParseHelperCopy(props.helper);
        if (helperobj.pre.length > 0) {
            helpercopy_pre = <span dangerouslySetInnerHTML={{ __html: helperobj.pre }} ></span>;
        }
        if (helperobj.post.length > 0) {
            helpercopy_post = <span dangerouslySetInnerHTML={{ __html: helperobj.post }} ></span>;
        }
    }
    // end of helper copy handling

    // handle label name
    const labelname = props.name + "label";

    return (props.show && 
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <Box sx={{ my: 1 }}>
                    <label htmlFor={props.name}>{props.label} </label>
                    <Box component="span" sx={{ color: 'red' }}>
                        {labelreq}
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={8}>
                <div>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                        {helpercopy_pre}
                        <InputLabel id={labelname}></InputLabel>
                        <Select
                            labelId={labelname }
                            id={props.name}                    
                            multiple
                            autoWidth
                            styles={customStyles}
                            name={props.name}
                            value={multivalues}
                            onChange={props.onChange}
                            input={<OutlinedInput  />}
                            MenuProps={MenuProps}
                        >
                            {seloptions.map((soption) => (
                                <MenuItem
                                    key={soption.value}
                                    value={soption.value}
                                    style={getStyles(soption.value, props.value, theme)}
                                >
                                    {soption.text}
                                </MenuItem>
                            ))}
                        </Select> 
                        <FormHelperText sx={{ marginLeft: 0 }}>{helpercopy_post}</FormHelperText>
                    </FormControl>
                </div>
            </Grid>
        </Grid >
    );
}
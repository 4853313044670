import MainNav from '../MainNav/MainNav';

export default function About() {
    return (
        <div>  
            <MainNav /> 
            <h2>About this form</h2>
            <div>
                <p>
                    We ask that you complete and submit this form by Monday, August 12.
                </p>
                <p>
                    This form may only be submitted once. It can, however, 
                    be saved as a work in progress by clicking the save button found 
                    at the bottom of each page before logging out. <span className="boldtext">Please save each page as you go  
                    and before logging out. Please finalize and click SUBMIT by 8pm ET/5pm PT Monday, August 12, 2024.</span>
                </p>
                <p>
                    If you have any questions, or you wish to make a change after you have 
                    submitted your form, please contact the Publicist Registration Team 
                    at <a className="lightblue" href="mailto:publicistregistration@tiff.net">publicistregistration@tiff.net</a>. 
                </p>
            </div>
        </div>        
    );
}
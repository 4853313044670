import React from 'react';
import './FormElements.css';
import Grid from "@mui/material/Grid";
import useSession from '../../useSession';
import Box from '@mui/material/Box';

const Copy = props => {

    const { sessionItem } = useSession();

    let htmltext = props.value;
    if (sessionItem.film) {
        for (const [key, value] of Object.entries(sessionItem.film)) {
            const sourcetext = "{film."+{ key }.key+"}" ;
            const vartext = { value }.value;            
            htmltext = htmltext.replace(sourcetext, vartext)
        }
    }
    const mycopy = <span className={props.classname} id={props.id} dangerouslySetInnerHTML={{ __html: htmltext }} ></span>;
    let myheader = "";
    if (props.header) {
        myheader = props.header.replace("null", "");
    }
    // variable replacement in copy text using filmobj property names
    
    const mygrids = [];
    // handle columns prop passed from caller component
    // we have either a 2 column or a single column mode
    if (props.columns === 2) {
        // 2 column layout
        const col1 = <Grid item key="col1" xs={4}><Box sx={{ marginTop: 1 }}>{myheader}</Box></Grid>;
        mygrids.push(col1);
        const col2 = <Grid item key="col2" xs={8}><Box sx={{ marginLeft: 1.3, marginTop: 1}}>{mycopy}</Box></Grid>;
        mygrids.push(col2);
    } else {
        // single column layout
        const col3 = <Grid item key="col1" xs={12}>{mycopy}</Grid>;
        mygrids.push(col3);
    }


    return (
        <Grid container margin={0}>
            {mygrids}
        </Grid>       
    );
};

export default Copy;
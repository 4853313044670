import React, { useState } from 'react';
import './FormElements.css';
import Grid from "@mui/material/Grid";
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ParseHelperCopy } from '../Helpers/Helpers';
import './FormElements.css';

export default function MultiCheck(props) {

    const [mvalue, setMvalue] = useState(props.value); 

    let stateobj = {}; 

    const mvaluearray = mvalue.split(",");
    mvaluearray.forEach((vitem) => {
        if (isChecked(vitem)) {
            stateobj[vitem] = true;
        } else {
            stateobj[vitem] = false;
        }
    });

    function isChecked(optionname) {
        let result = false;
        if (mvalue.includes(optionname)) {
            result = true;
        }
        return result
    }  

    function handleChangeCheck(evt) {
        const target = evt.target.name.replace(props.name + "_", "");
        let newvalue = mvalue;        
        if (evt.target.checked) {            
            if (mvalue === "") {
                setMvalue(target);
                newvalue = target;
            } else {
                setMvalue(mvalue + "," + target)
                newvalue = mvalue + "," + target;
            }
        }
        else {
            if (mvalue.includes(target)){
                newvalue = mvalue.replace(target, "");
                newvalue = newvalue.replace(",,", ",");
                setMvalue(newvalue);
            }
        }  
        props.onChange(props.name, newvalue);
    }

    let seloptions = [];
    let poptions = props.options;     
    // check if options passed have values and text items
    if (poptions) {
        props.options.forEach((item) => {
            let itemvalue = item;
            let optiontext = item
            let optionvalue = item;
            if (itemvalue.includes(":")) {
                // split value and text if value is formatted as "text:value"
                const myArray = itemvalue.split(":");
                optiontext = myArray[0];
                optionvalue = myArray[1];
            }
            const lvalue = {};
            lvalue.value = optionvalue;
            lvalue.text = optiontext;
            seloptions.push(lvalue);
            stateobj[optionvalue] = isChecked(optionvalue);
            
        })
    }

    // handle required label
    let labelreq = "";
    if (props.req) {
        labelreq = " *";
    }

    // logic for handling helper copy before or after main control    
    let helpercopy_pre = "";
    let helpercopy_post = "";
    if (props.helper) {
        const helperobj = ParseHelperCopy(props.helper);
        if (helperobj.pre.length > 0) {
            helpercopy_pre = <span dangerouslySetInnerHTML={{ __html: helperobj.pre }} ></span>;
        }
        if (helperobj.post.length > 0) {
            helpercopy_post = <span dangerouslySetInnerHTML={{ __html: helperobj.post }} ></span>;
        }
    }
    // end of helper copy handling


    // handle label name
    const labelname = props.name + "label";

    return (props.show &&
        <Grid container spacing={1}>
            <Grid item xs={4} >
                <Box sx={{ my: 1}}>
                    <label htmlFor={props.name}>{props.label} </label>
                    <Box component="span" sx={{ color: 'red' }}>
                    {labelreq}
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={8}>
                <div>
                    <FormControl sx={{ m:1, minWidth: 120 }} component="fieldset" variant="standard">
                        {helpercopy_pre}
                        <FormLabel component="legend" id={labelname}></FormLabel>  
                            <FormGroup>
                                {seloptions.map((soption) => (                                    
                                    <FormControlLabel key={soption.value} 
                                    control={
                                            <Checkbox checked={stateobj[soption.value]} onChange={handleChangeCheck} name={props.name + "_" + soption.value} />
                                            }
                                    label={soption.text}
                                />
                                ))} 
                            <input type="hidden" id={props.name + "_hidden"} name={props.name + "_hidden"} value={mvalue}></input>
                            </FormGroup>
                        <FormHelperText sx={{ marginLeft: 0 }}>{helpercopy_post}</FormHelperText>
                    </FormControl>
                </div>
            </Grid>
        </Grid >
    );
}
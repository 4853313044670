import React from 'react';
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import { ParseHelperCopy } from '../Helpers/Helpers';

export default function DropDown(props) {
           
    let seloptions = [];

    let poptions = props.options;

    //handle case when value provided is integer=0
    let myvalue = props.value;
    //if (props.value === 0) {
    //    myvalue = "";
    //}

    // add empty select at the top
    let emptysel = "";
    if (props.custom_props) {
        if (props.custom_props.emptysel) {
            emptysel = props.custom_props.emptysel;
        }
    } 
    const defitem = <MenuItem key="default" value={emptysel}> -Select- </MenuItem>;
    seloptions.push(defitem);

    if (poptions) {
        props.options.forEach((item) => {           
            let selected = ""            
            let itemvalue =  item ;
            let optiontext = item 
            let optionvalue =  item 
            if (itemvalue.includes(":")) {
                // split value and text if value is formatted as "text:value"
                const myArray = itemvalue.split(":");
                optiontext = myArray[0];
                optionvalue = myArray[1];
            }
            if (myvalue === { optionvalue }) {
                selected = "selected"
            }
            const oitem = <MenuItem key={item} {...selected} value={optionvalue}>{optiontext}</MenuItem>;
            seloptions.push(oitem);            
        })
    }

    // handle required label
    let labelreq = "";
    if (props.req) {
        labelreq = " *";
    }   

    let labelname = props.name + "label"; 
    

    // logic for handling helper copy before or after main control    
    let helpercopy_pre = "";
    let helpercopy_post = "";
    if (props.helper) {
        const helperobj = ParseHelperCopy(props.helper);
        if (helperobj.pre.length > 0) {
            helpercopy_pre = <span dangerouslySetInnerHTML={{ __html: helperobj.pre }} ></span>;
        }
        if (helperobj.post.length > 0) {
            helpercopy_post = <span dangerouslySetInnerHTML={{ __html: helperobj.post }} ></span>;
        }
    }
    // end of helper copy handling

    return (props.show &&        
        <Grid container spacing={1}>
            <Grid item xs={4} >
                <Box sx={{ my: 1 }}>
                    <label htmlFor={props.name}>{props.label} </label>
                    <Box component="span" sx={{ color: 'red' }}>
                        {labelreq}
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={8} >                
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    {helpercopy_pre}
                    <InputLabel id={labelname}></InputLabel>
                    <Select
                            labelId={labelname }
                            id={props.name} 
                            name={props.name}
                            value={myvalue}
                            onChange={props.onChange}
                    >
                            {seloptions}
                    </Select> 
                    <FormHelperText sx={{ marginLeft: 0 }}>{helpercopy_post}</FormHelperText>
                </FormControl>
            </Grid>
        </Grid >
    );
}
import React, { useState } from 'react';
import './FormElements.css';
import Grid from "@mui/material/Grid";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import { ParseHelperCopy } from '../Helpers/Helpers';

export default function RadioButtonList(props) {
       
    // handle required label
    let labelreq = "";
    if (props.req) {
        labelreq = " *";
    }

    // handle option values passed
    let radiooptions = [];
    props.options.forEach((option) => {
        if (option.includes(":")) {
            // case for radio item with helper text for each option
            const multivalue = option.split(":");
            const optionitemh = <React.Fragment key={multivalue[0]}>
                <FormControlLabel key="optiontrue" value={multivalue[0]} control={<Radio />} label={multivalue[0]} />
                <FormHelperText sx={{ marginLeft: 0, paddingTop:0, marginTop:0, marginBottom:2}}>{multivalue[1]}</FormHelperText>
            </React.Fragment>;
            radiooptions.push(optionitemh);
        }
        else {
            const optionitem = <React.Fragment>
                <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
            </React.Fragment>;
            radiooptions.push(optionitem);
        }
    });
    
    const [defvalue] = useState(props.value);    

    // logic for handling helper copy before or after main control    
    let helpercopy_pre = "";
    let helpercopy_post = "";
    if (props.helper) {
        const helperobj = ParseHelperCopy(props.helper);
        if (helperobj.pre.length > 0) {
            helpercopy_pre = <span dangerouslySetInnerHTML={{ __html: helperobj.pre }} ></span>;
        }
        if (helperobj.post.length > 0) {
            helpercopy_post = <span dangerouslySetInnerHTML={{ __html: helperobj.post }} ></span>;
        }
    }
    // end of helper copy handling

    return (
        <Grid container spacing={1}>
            <Grid item xs={4} >
                <Box sx={{ my: 1 }}>
                    <label htmlFor={props.name} >{props.label}</label>
                    <Box component="span" sx={{ color: 'red' }}>
                        {labelreq}
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={8}>
                <Box
                    sx={{
                        width: 800,
                        maxWidth: '100%',
                        alignItems: 'left'
                    }}
                >
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <FormLabel id={props.name + '-label'}></FormLabel>
                        {helpercopy_pre}
                        <RadioGroup                            
                            defaultValue={defvalue}
                            aria-labelledby={props.name + '-label'}
                            name={props.name}
                            onChange={props.onChange}
                        >
                            {radiooptions}
                        </RadioGroup>
                        <FormHelperText sx={{ marginLeft: 0 }}>{helpercopy_post}</FormHelperText>
                    </FormControl>                   
                </Box>
            </Grid>
        </Grid>
    );
}
import React from 'react';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";


export default function StatusRow(props) {

    // handle required label
    let labelreq = "";
    if (props.req) {
        labelreq = " *";
    }
    let propvalue = "";
    if (props.value) {
        propvalue = props.value;
    }

    const itemvalue = propvalue.replace("Contact", "<strong>Contact</strong>").replace("Company", "<strong>Company</strong>").replace("(Null)", "").replace("Liason", "<strong>Liason</strong>");
    const itemcopy = <span dangerouslySetInnerHTML={{ __html: itemvalue }} ></span>;

    const layoutcol1 = <Grid key="col1" item xs={6} sx={{ borderBottom: 1, borderColor: 'grey.400'}} ><Box component="span">{props.propname}</Box><Box component="span" sx={{ color: 'red' }}>{labelreq}</Box></Grid>;
    const layoutcol2 = <Grid key="col2" item xs={6} sx={{ borderBottom: 1, borderColor: 'grey.400' }} ><Box >{itemcopy}</Box><Box sx={{ color: 'red' }}>{props.message}</Box></Grid>;
       

    return (<Grid container key="col0" sx={{ marginLeft: 1, marginTop: 1 }}>{layoutcol1}{layoutcol2}</Grid>);
}
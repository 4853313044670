import { useState } from 'react';

export default function useSession() {
    const getSession = () => {
        const sessionString = sessionStorage.getItem('tiff_film');       
        return JSON.parse(sessionString);
    };

    const [sessionItem, setSession] = useState(getSession());

    const saveSession = userSession => {
        sessionStorage.setItem('tiff_film', JSON.stringify(userSession));
        setSession(userSession);
    };

    return {
        setSession: saveSession,
        sessionItem
    }

}
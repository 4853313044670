import React, { useState, useEffect } from 'react';
import { GetCompletionStatus } from '../ApiClient/ApiClient';
import SectionStatus from "./SectionStatus";
import useSession from '../../useSession';
import Grid from "@mui/material/Grid";
import MainNav from '../MainNav/MainNav';
import { GetTermsRead } from '../Helpers/Helpers';

function ParseQs(qsname) {
    let params = (new URL(document.location)).searchParams;
    let qsvalue = "";
    if (params.has(qsname)) {
        qsvalue = params.get(qsname);
    }
    return qsvalue;
} 

export default function Status() {

    const { sessionItem } = useSession();
    const final = sessionItem.film.submittedfinal;
    let changed = sessionItem.film.changed;
     
    let sectionid = 1;
    const qssection = ParseQs("sectionid");
    if (qssection) {
        sectionid = qssection;
    }    

    // For PA this is the main page
    // let's make sure that terms are read
    // if not redirect to agreeterms page
    // we will refactor that page to display a message
    // with no question ask
    const filmid = sessionItem.film.filmObjId;
    const readterms = GetTermsRead(filmid);
    if (!readterms) {
        window.location.href = '/agreeterms';
    } 
          
    const [opMessage, setOpMessage] = useState("");
    const [statusData, setStatusData] = useState();

    // call API hook
    useEffect(() => {  
        let sectionid = 1;
        const qssection = ParseQs("sectionid");
        if (qssection) {
            sectionid = qssection;
        }
        const credentials = sessionItem.accesstoken;
        const filmid = sessionItem.film.filmObjId;
        const formid = sessionItem.form.form_id;
        const getStatusData = async () => {            
            const respo = await GetCompletionStatus(credentials, filmid, formid, sectionid);
            if (respo.film_id && respo.film_title) {
                setStatusData(respo);               
            } else {
                // handle error             
                setOpMessage("Unexpected error while loading the data for this page.")                
            }
        };
        getStatusData();
    }, [sessionItem.accesstoken, sessionItem.film.filmObjId, sessionItem.form.form_id]);
    // end of async call logic

    if (statusData) {
        // pass API response to Section Status Component
        let details = [];
        let completed = false;
        if (statusData.details) {
            details = statusData.details;           
        }
        // generate status message
        if (statusData.completed) {
            completed = true;            
        }
        // update changed property from the completion status data
        if (statusData.changed) {
            changed = true;
        }
        // for PA we display the submit final section in this page
        // we also use the changed property of the filmobj
        // to determine the copy on the submit final action
        let finalcontent = "";
        if (completed) {            
            // display submit final action if completed
            if (changed) {
                finalcontent = <div className="dashboard-final"><a className="lightblue" href="/submitfinal">Proceed to Submit Final Page</a></div>;
            } else {
                finalcontent = <div className="dashboard-final"><a className="lightblue" href="/submitfinal">Proceed to Submit Final Page - No Change</a></div>;
            }            
        }
        // if film is submitted final indicate it in submit final div
        // and remove the link
        if (final) {
            finalcontent = <div className="dashboard-final">This film information has been submitted final. If amendments to the information supplied are required, they must be sent
                directly to Festival staff via email.</div>;
        }
        // render SectionStatus Component that displays the completion status of a form section
        return (<div>
                  <div>
                <MainNav />
                <article>
                    <h2>WHY SHOULD YOU CONFIRM YOUR PUBLICISTS?</h2>
                    <ul>
                        <li>
                            The publicist contact information you supply here for each territory will be shared
                            with the TIFF accredited media list. This gives
                            accredited media the correct contacts for your film.
                        </li>
                        <li>
                            It will provide context for the Publicist Registration form (to be sent as a follow up).
                        </li>
                    </ul>
                    <p>
                        You should review and confirm the information by Monday, August 12, 2024 at
                        8:00pm ET/5:00pm PT. Please note: The sooner this information is confirmed,
                        the sooner we will reach out regarding registration for Publicist passes.
                    </p>
                    <p>
                        This form may be updated and saved several times, but you must click SUBMIT
                        to finalize it before the deadline. However, <span className="boldtext">once it is submitted it is considered final
                        and you may not resubmit changes through the system.</span>
                    </p>
                    <p>
                        Please note: You must mark one of the publicists as the Primary publicist. You
                        won't be able to submit your completed form without doing this.
                    </p>
                </article>      
                  </div>
                    <SectionStatus details={details} completed={completed} sectionid={sectionid} final={final} />
                    {finalcontent}
                  </div>)
    } 

    return (<Grid container spacing={1} >
                <Grid item xs={12}>
                    {opMessage}
                 </Grid >    
            </Grid>);
}
import React from 'react';
import './FormElements.css';
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import RoleTable from './RoleTable';


export default function RoleGrid(props) {

    // handle required label    
    let labelreq = "";
    if (props.req) {
        labelreq = " *";
    } 

    function handlePrimary(primary_group, mainid, secid, isPrimary) {
        props.onPrimary(primary_group, mainid, secid, isPrimary);
    }

    // this function should propagate up to section.js to save the film object
    // useful to save temp data in session (but not API) when navigating away to the role page
    function saveSnapshot() {
        props.saveSnapshot();
    }   
   
    const helpercopy = <span dangerouslySetInnerHTML={{ __html: props.helper }} ></span>;
       
    const itemAdd = "/role?id=" + props.roleid + "&sectionid=" + props.sectionid; 

    // function to trigger on add button click
    function handleAddClick() {
        saveSnapshot()
    }

    return (props.show &&
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <h3>{props.header}
                    <Box component="span" sx={{ color: 'red' }}>
                        {labelreq}
                    </Box>
                </h3>
                {helpercopy}
                <br/>
                <Button variant="outlined" href={itemAdd} onClick={handleAddClick} startIcon={<AddIcon />}>
                    Add {props.header}
                </Button>

                <RoleTable roledata={props.roledata} filmid={props.filmid}
                    roleid={props.roleid} sectionid={props.sectionid}
                    custom_props={props.custom_props} rolePrimaries={props.rolePrimaries}
                    handlePrimary={handlePrimary} />  
            </Grid>            
        </Grid>
    );
} 
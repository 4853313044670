import React, { useState, useEffect } from 'react';
import DashBoardSection from '../DashBoardSection/DashBoardSection';
import useSession from '../../useSession';
import MainNav from '../MainNav/MainNav';
import { GetTermsRead } from '../Helpers/Helpers';
import { GetCompletionStatus } from '../ApiClient/ApiClient';
import './DashBoard.css';


export default function Dashboard() { 

    const { sessionItem, setSession } = useSession();  
    const [sectionitems, setSectionItems] = useState(sessionItem.section_status);

    const isfinal = sessionItem.film.submittedfinal;

    // pull film review async and update sessionItem.section_status
    // call API hook
    useEffect(() => {
        let sectionid = 0;
        const credentials = sessionItem.accesstoken;
        const filmid = sessionItem.film.filmObjId;
        const formid = sessionItem.form.form_id;
        const getStatusData = async () => {
            const respo = await GetCompletionStatus(credentials, filmid, formid, sectionid);
            if (respo.section_status) {
                sessionItem.section_status = respo.section_status;
                var today = new Date();
                var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                var time = today.getHours() + ":" + today.getMinutes();
                sessionItem.dashboardupdate = date + " " + time;  
                setSectionItems(respo.section_status);
            } else {
                // handle error?           

            }
            // reset dashboardrefresh to run this only once
            sessionItem.dashboardrefresh = false;
            // save updated sessionItem
            setSession(sessionItem);
        };
        // call the API only if sessionItem.dashboardrefresh=true
        if (sessionItem.dashboardrefresh) {
            getStatusData();
        }
       
    }, [sessionItem, setSession, setSectionItems]);
    // en of useEffect

    // make sure that terms are read
    // if not redirect to agreeterms page
    const filmid = sessionItem.film.filmObjId;
    const readterms = GetTermsRead(filmid);
    if (!readterms) {
        window.location.href = '/agreeterms';
    } 

    //const sectionitems = sessionItem.section_status;
    const sectionitemList = [];

    let allcomplete = true;

    sectionitems.forEach((item) => {
        const element = <DashBoardSection key={item.section_id} sectionid={item.section_id} completed={item.completed} sectionname={item.section_name} dashboard_copy={item.dashboard_copy} final={isfinal} />;
        sectionitemList.push(element);
        // track if all sections are complete
        // if one is not we can't display the submit final action
        if (!item.completed) {
            allcomplete = false;
        }
    })

    let checklist = "TIFFFilmInvitationChecklist2022.pdf";
    // checklist file is different depending on the formtype
    if (sessionItem) {
        if (sessionItem.form) {
            if (sessionItem.form.checklist) {
                checklist = sessionItem.form.checklist;
            }
        }
    }

    let finalcontent = "";
    // allcomplete = true;
    if (allcomplete) {
        finalcontent = <div className="dashboard-final"><a className="lightblue" href="/submitfinal">Click here to review all the information and submit this invitation form.</a></div>;
    } 

    // if film is submitted final indicate it in submit final div
    if (sessionItem.film.submittedfinal) {
        finalcontent = <div className="dashboard-final">This film has been submitted final. If amendments to the information supplied are required, they must be sent
            directly to Festival staff via email.</div>;
    }


    return (
        <div> 
            <MainNav/> 
            <h2>Dashboard</h2>
            <div>
                <p>
                    We ask that you complete and submit this form within 48 hours of receiving the
                    Film Invitation Package.
                </p>
                <p>
                    This form may be submitted only once. It can, however, be saved as a work in progress
                    by clicking the save button found at the bottom of
                    each page before logging out. Please save each page as you go.
                </p>
                <p>
                    If you have any questions, please contact festivalinvitations@tiff.net
                </p>
            </div>            
            <div className="dashboard-highlight">
                You might find this <a className="lightblue" href={"/pdf/"+checklist} rel="noreferrer" target="_blank">Film Information Checklist</a> a handy reference in preparing your application. 
            </div>
            <div className="dashboard-wrapper">
               {sectionitemList}
            </div>
            {finalcontent}       
            
        </div>         
    );
}
import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import useSession from './useSession';
import About from './components/About/About';
import Print from './components/Print/Print';
import Login from './components/Login/Login';
//import MainNav from './components/MainNav/MainNav';
import Section from './components/Section/Section';
import AgreeTerms from './components/AgreeTerms/AgreeTerms';
import SubmitFinal from './components/SubmitFinal/SubmitFinal';
import Role from './components/Role/Role';
import Entity from './components/Entity/Entity';
import Status from './components/Status/Status';
import AddContact from './components/AddContact/AddContact';
//import Copy from './components/FormElements/Copy';
import Dashboard from './components/Dashboard/Dashboard';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
//import Grid from "@mui/material/Grid";

function App() {    
    const { sessionItem, setSession } = useSession();    
        
    if (!sessionItem) {
        return <Login setSession={setSession} />
       
    } 
    
    return (
        <React.Fragment>
            <CssBaseline />
            <Container>
                <Box sx={{ minWidth: 800 }}>
                    {/*<Grid container>*/}
                    {/*    <Grid item xs={1} sx={{ verticalAlign: 'bottom' }}>*/}
                    {/*        <Box*/}
                    {/*            component="img"*/}
                    {/*            sx={{*/}
                    {/*                width: 60,*/}
                    {/*                minWidth: { xs: 50 },*/}
                    {/*            }}*/}
                    {/*            alt="tiff"*/}
                    {/*            src="/img/TIFF_logo_rgb_mini46.png"*/}
                    {/*        />*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={11} sx={{ verticalAlign: 'bottom' }}>*/}
                    {/*        <Box sx={{ fontWeight: 'bold', marginTop: 2.7 }}>Publicity Contacts Confirmation</Box>*/}
                    {/*    </Grid> */}
                    {/*</Grid>*/}


                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',  
                            borderRadius: 1                                                      
                        }}
                    >
                        <Box
                            component="img"
                            sx={{
                                width: 60,
                                minWidth: { xs: 50 },
                            }}
                            alt="tiff"
                            src="/img/TIFF_logo_blk_TM-01_45.png"
                        /> 
                        <Box sx={{ fontWeight: 'bold' }}>Publicity Contacts Confirmation</Box>
                    </Box>
                    <h2>Film: {sessionItem.film.engTitle}</h2>
                    <Box > 
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Status />}>
                            </Route>
                            <Route path="/dashboard" element={<Dashboard />}>
                            </Route>
                            <Route path="/about" element={<About />}>
                            </Route>
                            <Route path="/print" element={<Print />}>
                            </Route>
                            <Route path="/section" element={<Section />}>
                            </Route>
                            <Route path="/agreeterms" element={<AgreeTerms />}>
                            </Route>
                            <Route path="/submitfinal" element={<SubmitFinal />}>
                            </Route>
                            <Route path="/role" element={<Role />}>
                            </Route>
                            <Route path="/entity" element={<Entity />}>
                            </Route>
                            <Route path="/status" element={<Status />}>
                            </Route>
                            <Route path="/addcontact" element={<AddContact />}>
                            </Route>
                        </Routes>
                    </BrowserRouter>  
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 12,
                    marginTop:5
                }}>
                    &copy; 2024 Toronto International Film Festival. All rights reserved.
                </Box>
            </Container>
        </React.Fragment>
    );
}

export default App;

import React, { useState, useEffect } from 'react';
import { GetCompletionStatus } from '../ApiClient/ApiClient';
import SectionStatus from "../Status/SectionStatus";
import useSession from '../../useSession';
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box'; 
import MainNav from '../MainNav/MainNav';

export default function Print() {

    const { sessionItem } = useSession()    

    const [opMessage, setOpMessage] = useState("");
    const [statusData, setStatusData] = useState();

    const final = sessionItem.film.submittedfinal;

    // call API hook
    useEffect(() => {
        let sectionid = 0;        
        const credentials = sessionItem.accesstoken;
        const filmid = sessionItem.film.filmObjId;
        const formid = sessionItem.form.form_id;
        const getStatusData = async () => {
            const respo = await GetCompletionStatus(credentials, filmid, formid, sectionid);
            if (respo.film_id && respo.film_title) {
                setStatusData(respo);
            } else {
                // handle error             
                setOpMessage("Unexpected error while loading the data for this page.")
            }
        };
        getStatusData();
    }, [sessionItem.accesstoken, sessionItem.film.filmObjId, sessionItem.form.form_id]);
    // end of async call logic

    const statuslist = [];

    let filmcomplete = false;
    let filmtitle = "";

    if (statusData) {

        // break API response into sections
        // get number of sections and create a for loop
        const sectioncount = sessionItem.form.form_sections.length;
        for (let i = 1; i <= sectioncount; i++) {            
            const section_id = i;
            const filtered_details = statusData.details.filter(function (detail) {
                return detail.section_id === i;
            });
            let sectioncomplete = true; 
            if (Array.isArray(filtered_details)) {
                filtered_details.forEach((fdetail) => {
                    if (!fdetail.completed) {
                        sectioncomplete = false;                        
                    }
                });
            }
            statuslist.push(<SectionStatus key={"sectionstatus_" + i.toString()} details={filtered_details} completed={sectioncomplete} sectionid={section_id} opMessage={opMessage} final={final} />)
        }

        // generate status message
        if (statusData.completed) {
            filmcomplete = true;
        }
        filmtitle = statusData.film_title;
    }

    return (<div>             
                <MainNav />        
                <Box sx={{ marginTop: 1}}>
                    {filmcomplete && <Grid item xs={12} >
                    <Box sx={{ fontSize: 'h6.fontSize', marginTop: 1, fontWeight: 'bold' }}>{filmtitle} is Completed.</Box>
                    </Grid>}
                    {statuslist}
                    <Grid item xs={12}>
                        {opMessage}
                    </Grid >  
                </Box>
            </div>);
}
import React from 'react';
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import StatusRow from "./StatusRow";
import AddIcon from '@mui/icons-material/Add';


export default function SectionStatus(props) {  
    
    const sectionid = props.sectionid;
    const updateurl = "/section?id=" + sectionid.toString();       
    let sectionheader = "Section " + sectionid.toString() + ": ";
    let sectionname = "";

    const isfinal = props.final;

    // generate status Rows
    const statusRows = [];
    let mainStatusMessage = "Review the information below to see what you still have to complete for this section.";
    let statusBox = <Box sx={{ backgroundColor: "#fcf8e3", marginTop: 1, p: 1 }}>{mainStatusMessage}</Box>;

    if (props.details) {
        // generate each entry row
        let count = 1;
        props.details.forEach((item) => {
            let itemmsg = "";
            if (item.status_message) {
                itemmsg = item.status_message;
            }
            let reqitem = false;
            if (item.required) {
                reqitem = true;
            }
            let rowid = "strow" + count.toString();
            const statusitem = <StatusRow key={rowid} propname={item.item_caption} value={item.item_value} req={reqitem} message={itemmsg}></StatusRow>;
            statusRows.push(statusitem);
            count += 1;
            // get the section name from one of these elements
            if (sectionname === "") {
                sectionname = item.section_name;
            }
        })
    }
    // generate status message
    if (props.completed) {
        mainStatusMessage = <span dangerouslySetInnerHTML={{ __html: "<strong>This section has been marked as complete</strong>. You may continue to update the fields until your film is submitted." }} ></span>;
        statusBox = <Box sx={{ backgroundColor: "#dff0d8", marginTop:1, p:1 }}>{mainStatusMessage}</Box>;
    }


    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Box sx={{ fontSize: 'h6.fontSize', marginTop: 1, fontWeight: 'bold' }}>{sectionheader} {sectionname}</Box>
                {statusBox}
            </Grid>
            {!isfinal &&
            <Grid item xs={12}>
                <Button variant="outlined" href={updateurl} startIcon={<AddIcon />}>
                    Update
                </Button>
            </Grid>
            }
            {statusRows}                                            
        </Grid >
    );
}